import React, { useState, useContext } from "react";
import "./App.css";
import { useHistory } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import userData from "./employee.json";
import {
  Header,
  Button,
  Segment,
  Grid,
  Form,
  Message,
  Checkbox,
  Popup,
} from "semantic-ui-react";
import { Contexto } from "./Contexto";

function Login() {
  const {
    ["user"]: [user, setUser],
  } = useContext(Contexto);
  const {
    ["recordar"]: [recordar, setRecordar],
  } = useContext(Contexto);
  const {
    ["pagina"]: [pagina, setPagina],
  } = useContext(Contexto);
  const history = useHistory();
  const [password, setPassword] = useState();
  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userPassword") {
      setPassword(value);
    }
  };
  const login = (event, password) => {
    event.preventDefault();
    var found = false;
    for (var i = 0; i < userData.length; i++) {
      if (userData[i].id === password) {
        found = true;
        setUser(userData[i].Nombre);
        sessionStorage.user = userData[i].Nombre;
        if (recordar) {
          localStorage.user = userData[i].Nombre;
        }
        break;
      }
    }
    if (!found) alert(`Usuario No encontrado`);
    if (found) {
      if (pagina) {
        history.push(pagina);
      } else {
        history.push("/");
      }
    }
  };
  return (
    <Grid textAlign="center" style={{ height: "50vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" style={{ color: "#F44336" }} textAlign="center">
          Ingresar con su Numero de Empleado
        </Header>
        <Form size="large">
          <Segment stacked>
            <Popup
              content="Numero de Empleado sin PA ni los 0 iniciales, Ejemplo: PA00123456 = 123456"
              trigger={
                <Form.Input
                  name="userPassword"
                  type="password"
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Numero de Empleado"
                  onChange={(event) => onChangeHandler(event)}
                />
              }
            />

            <Button
              color="red"
              fluid
              size="large"
              onClick={(event) => {
                login(event, password);
              }}
            >
              Login
            </Button>
          </Segment>
        </Form>
        <Message>
          <Checkbox
            label="Recordar Usuario"
            onChange={(e, { checked }) => {
              checked ? setRecordar(true) : setRecordar(false);
            }}
          />
        </Message>
      </Grid.Column>
    </Grid>
  );
}

export default Login;
