import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";
import "date-fns";
import "./App.css";
import {
  Container,
  Button,
  Input,
  Icon,
  Segment,
  Dropdown,
  Dimmer,
  Loader,
  Accordion,
  Label,
  Item,
  Modal,
  Image,
  Header,
} from "semantic-ui-react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import "react-datepicker/dist/react-datepicker.css";
import userData from "./employee.json";

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25568);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}

function Consultas({ user }) {
  //Fechas
  let today = new Date();
  let startDate = new Date(today);
  startDate.setDate(startDate.getDate() - 30);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var hora = today.getHours();
  var minutos = today.getMinutes();
  dd < 10 ? (dd = "0" + dd) : (dd = today.getDate());
  mm < 10 ? (mm = "0" + mm) : (mm = today.getMonth() + 1);
  if (hora < 10) hora = "0" + hora;
  if (minutos < 10) minutos = "0" + minutos;
  let date = yyyy + "-" + mm + "-" + dd;
  //Fechas
  const [data, setData] = useState();
  const [myData, setMyData] = useState();
  const [dataFound, setDataFound] = useState();
  const [inicio, setInicio] = useState(startDate.toISOString().slice(0, 10));
  const [final, setFinal] = useState(date);
  const [activeIndex, setActiveIndex] = useState(0);
  const [buscar, setBuscar] = useState(false);
  const [creador, setCreador] = useState([]);
  const [activeItem, setActiveItem] = useState("");
  const dummyImg = "https://react.semantic-ui.com/images/wireframe/image.png";
  const [open, setOpen] = useState(false);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  var j;
  var lastr = userData.length;
  const personal = [];
  for (j = 0; j < lastr; j++) {
    if (userData[j].Nombre !== "") {
      personal.push({
        key: userData[j].Nombre,
        text: userData[j].Nombre,
        value: userData[j].Nombre,
      });
    }
  }

  async function sacardata() {
    setBuscar(true);
    var datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[0];
    const rows = await sheet.getRows();
    let lastr = rows.length + 1;
    var j;
    await sheet.loadCells("A1:V" + lastr.toString());
    for (j = 1; j < lastr; j++) {
      const ar = sheet.getCell(j, 0);
      const br = sheet.getCell(j, 1);
      const cr = sheet.getCell(j, 2);
      const dr = sheet.getCell(j, 3);
      const er = sheet.getCell(j, 4);
      const fr = sheet.getCell(j, 5);
      const gr = sheet.getCell(j, 6);
      const hr = sheet.getCell(j, 7);
      const ir = sheet.getCell(j, 8);
      const jr = sheet.getCell(j, 9);
      const kr = sheet.getCell(j, 10);
      const lr = sheet.getCell(j, 11);
      const mr = sheet.getCell(j, 12);
      const nr = sheet.getCell(j, 13);
      const or = sheet.getCell(j, 14);
      const pr = sheet.getCell(j, 15);
      const qr = sheet.getCell(j, 16);
      const rr = sheet.getCell(j, 17);
      const sr = sheet.getCell(j, 18);
      const tr = sheet.getCell(j, 19);
      const ur = sheet.getCell(j, 20);
      const vr = sheet.getCell(j, 21);
      datas.push({
        id: sr.value,
        fecha: ar.value,
        fecha_hora: br.value,
        nombre: cr.value,
        area: dr.value,
        rubro: er.value,
        categoria: fr.value,
        observaciones: gr.value,
        severidad: hr.value,
        pac: ir.value,
        fecha_cierre: jr.value,
        responsable: kr.value,
        anexo: lr.value,
        status: mr.value,
        confirmado: nr.value,
        extension: or.value,
        nueva_fecha: pr.value,
        detalle: qr.value,
        anexo2: rr.value,
        obsDetalle: tr.value,
        persObservadas: ur.value,
        aplica: vr.value,
      });
    }
    datas.sort(function (a, b) {
      var keyA = a.fecha_hora,
        keyB = b.fecha_hora;
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
    setData(datas);
    setMyData(
      datas.filter((item) => {
        return item.nombre === user;
      })
    );
    setDataFound(
      datas.filter((item) => {
        if (creador.length !== 0) {
          return (
            ExcelDateToJSDate(item.fecha).toISOString().slice(0, 10) >=
              inicio &&
            ExcelDateToJSDate(item.fecha).toISOString().slice(0, 10) <= final &&
            creador.includes(item.nombre)
          );
        } else {
          return (
            ExcelDateToJSDate(item.fecha).toISOString().slice(0, 10) >=
              inicio &&
            ExcelDateToJSDate(item.fecha).toISOString().slice(0, 10) <= final
          );
        }
      })
    );
    setBuscar(false);
    setActiveIndex(-1);
  }

  React.useEffect(() => {
    !data && sacardata();
    data && console.log(myData);
  }, []);

  return (
    <Container>
      <Segment>
        {activeItem && (
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
          >
            <Modal.Header>{activeItem.observaciones}</Modal.Header>
            <Modal.Content image>
              <Image size="medium" src={activeItem.anexo} wrapped />
              <Modal.Description>
                <Header>{activeItem.rubro + "-" + activeItem.categoria}</Header>
                <p>
                  <b>Detalle: </b> {activeItem.detalle}
                </p>
                <p>
                  <b>Fecha: </b>{" "}
                  {ExcelDateToJSDate(activeItem.fecha)
                    .toISOString()
                    .slice(0, 10)}
                </p>
                <p>
                  <b>Area: </b> {activeItem.area}
                </p>
                <p>
                  <b>Registrado por: </b> {activeItem.observador}
                </p>
                <p>
                  <b>Persona(s) Observada(s): </b> {activeItem.observado}
                </p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color="green" onClick={() => setOpen(false)}>
                OK
              </Button>
            </Modal.Actions>
          </Modal>
        )}

        <Dimmer active={buscar} page>
          <Loader>Loading</Loader>
        </Dimmer>
        <Accordion fluid>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Filtros
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <>
              <Input
                label="Desde"
                size="mini"
                type="date"
                onChange={(e) => {
                  setInicio(e.target.value);
                  console.log(e.target.value);
                  e.target.value > final && setFinal(e.target.value);
                }}
                value={inicio}
                defaultValue={inicio}
              />
              <br />
              <br />
              <Input
                label="Hasta"
                size="mini"
                type="date"
                onChange={(e) => {
                  setFinal(e.target.value);
                  console.log(e.target.value);
                  e.target.value < inicio && setInicio(e.target.value);
                }}
                value={final}
                defaultValue={final}
              />
              <br />
              <br />
              <Label>Registrado por </Label>
              <Dropdown
                fluid
                multiple
                search
                selection
                value={creador}
                placeholder="Registrado por..."
                options={personal}
                onChange={(e, { value }) => {
                  setCreador(value);
                }}
              />
              <br />
              <br />
              <Button onClick={sacardata}>Buscar</Button>
            </>
          </Accordion.Content>
        </Accordion>
      </Segment>
      {dataFound && (
        <Segment>
          <Item.Group divided>
            {dataFound.map((item) => (
              <Item key={item.id}>
                <Item.Content>
                  <Item.Header
                    as="a"
                    href={"/observacion/" + item.id}
                    onClick={(e) => {
                      setActiveItem((prevObject) => ({
                        ...prevObject,
                        id: item.id,
                        rubro: item.rubro,
                        categoria: item.categoria,
                        observaciones: item.observaciones,
                        detalle: item.obsDetalle,
                        area: item.area,
                        fecha: item.fecha,
                        observador: item.nombre,
                        observado: item.persObservadas,
                        anexo: item.anexo ? item.anexo : dummyImg,
                      }));
                      //setOpen(true);
                    }}
                  >
                    {item.rubro + " - " + item.categoria}
                  </Item.Header>
                  <Item.Meta>{item.observaciones}</Item.Meta>
                  <Item.Description>
                    {item.area +
                      " - " +
                      ExcelDateToJSDate(item.fecha).toISOString().slice(0, 10)}
                  </Item.Description>
                  <Item.Extra>Observador:{item.nombre}</Item.Extra>
                  <Item.Extra>
                    Personas Observadas:{item.persObservadas}
                  </Item.Extra>
                  <Item.Extra>{item.obsDetalle}</Item.Extra>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
        </Segment>
      )}
    </Container>
  );
}

export default Consultas;
