import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";
import "date-fns";
import "./App.css";
import { storage } from "./firebase/firebase";
import imageCompression from "browser-image-compression";
import {
  Container,
  Button,
  Image,
  Input,
  Icon,
  Segment,
  Statistic,
  Divider,
  Dimmer,
  Loader,
  Pagination,
  Card,
  Label,
  Checkbox,
  TextArea,
  List,
  Header,
  Form,
} from "semantic-ui-react";
import { GoogleSpreadsheet } from "google-spreadsheet";

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25568);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  var date_data = new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );

  return date_data;
}

function FechaString(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "short" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${da}-${mo}-${ye}`;
  return fechaStr;
}

function xltoDate(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${ye}-${mo}-${da}`;
  if (mo < 10) {
    fechaStr = `${ye}-0${mo}-${da}`;
  }

  return fechaStr;
}

function Tareas({ user }) {
  //Fechas
  let today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var hora = today.getHours();
  var minutos = today.getMinutes();
  dd < 10 ? (dd = "0" + dd) : (dd = today.getDate());
  mm < 10 ? (mm = "0" + mm) : (mm = today.getMonth() + 1);
  if (hora < 10) hora = "0" + hora;
  if (minutos < 10) minutos = "0" + minutos;
  let date = yyyy + "-" + mm + "-" + dd;
  //Fechas
  const dummyImg = "https://react.semantic-ui.com/images/wireframe/image.png";
  const [data, setData] = useState("");
  const [myData, setMyData] = useState("");
  const [myPac, setMyPac] = useState("");
  const [activoID, setActivoID] = useState("");
  const [pagina, setPagina] = useState(1);
  const [totalPagina, setTotalPagina] = useState(0);
  const [extension, setExtension] = useState("N");
  const [extDate, setExtDate] = useState(date);
  const [detalle, setDetalle] = useState("");
  const [previewImg, setPreviewImg] = useState(dummyImg);
  const [saving, setSaving] = useState(false);
  const [preventDate, setPreventDate] = useState(date);
  const [pap, setpap] = useState("");
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const [subir, setSubir] = useState(false);

  async function handleImageUpload(event) {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      var reader = new FileReader();
      var preview = document.querySelector("#blah");
      reader.onload = function (e) {
        preview.setAttribute("src", e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        setImageAsFile((imageFile) => compressedFile);
      } catch (error) {
        console.log(error);
      }
      setSubir(true);
    } else {
      setSubir(false);
      setImageAsFile("");
      setImageAsUrl("");
      preview = document.querySelector("#blah");
      preview.setAttribute("src", "");
      var archivoSelect = document.querySelector("#anexoImg");
      archivoSelect.value = "";
    }
  }

  const handleFireBaseUpload = (e) => {
    if (subir) {
      e.preventDefault();
      if (imageAsFile === "") {
        console.error(
          `not an image, the image file is a ${typeof imageAsFile}`
        );
      }
      const uploadTask = storage
        .ref(`/images/${imageAsFile.name}`)
        .put(imageAsFile);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref("images")
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setImageAsUrl((prevObject) => ({
                ...prevObject,
                imgUrl: fireBaseUrl,
              }));
            });
        }
      );
    }
  };

  const sacardata = async () => {
    var data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[0];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    const encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    setData(datas);
    setMyData(
      datas.filter((item) => {
        return item.responsable === user;
      })
    );
    setMyPac(
      datas.filter((item) => {
        return item.responsable === user && item.status !== "Completado";
      })
    );
    setTotalPagina(
      datas.filter((item) => {
        return item.responsable === user && item.status !== "Completado";
      }).length
    );
    try {
      setActivoID(
        datas.filter((item) => {
          return item.responsable === user && item.status !== "Completado";
        })[0].id
      );
      setExtension(
        datas.filter((item) => {
          return item.responsable === user && item.status !== "Completado";
        })[0].extension
      );
    } catch (err) {
      console.log(err);
    }
  };

  const iniciar = () => {
    var priceEls = document.querySelectorAll(".item");
    for (var i = 0; i < priceEls.length; i++) {
      var ele = priceEls[i].innerText;
      if (ele === "Usuario") {
        priceEls[i].click();
        break;
      }
    }
  };

  const registrar = async () => {
    setSaving(true);
    let extendido = extension;
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[0];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    let lastr = rows.length + 1;
    var j;
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      if (sheet.getCell(j, 18).value === activoID) {
        const mr = sheet.getCell(j, 12);
        const or = sheet.getCell(j, 14);
        const pr = sheet.getCell(j, 15);
        const qr = sheet.getCell(j, 16);
        const rr = sheet.getCell(j, 17);
        const wr = sheet.getCell(j, 22);
        const xr = sheet.getCell(j, 23);
        mr.value = "Pendiente Aprobacion";
        or.value = extendido;
        if (extDate) {
          pr.formula =
            "=date(" +
            extDate.substring(0, 4) +
            "," +
            extDate.substring(5, 7) +
            "," +
            extDate.substring(8, 10) +
            ")";
        }
        if (pap) {
          wr.value = pap;
        }
        if (preventDate) {
          xr.formula =
            "=date(" +
            preventDate.substring(0, 4) +
            "," +
            preventDate.substring(5, 7) +
            "," +
            preventDate.substring(8, 10) +
            ")";
        }
        if (detalle) {
          qr.value = detalle;
        }
        if (imageAsUrl.imgUrl) {
          rr.value = imageAsUrl.imgUrl;
        }
        await sheet.saveUpdatedCells();
        break;
      }
    }
    setSaving(false);
  };

  React.useEffect(() => {
    if (!data) {
      sacardata();
    }
  }, [data]);

  return (
    <Container>
      <Dimmer active={!data || saving} page>
        <Loader>{saving ? "Saving" : "Loading"}</Loader>
      </Dimmer>
      {user ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              defaultActivePage={1}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPagina}
              onPageChange={(e, { activePage }) => {
                setPagina(activePage);
                setActivoID(myPac[activePage - 1].id);
                setSubir(false);
                setImageAsUrl("");
                setImageAsFile("");
                setExtension(myPac[activePage - 1].extension);
                setExtension(date);
                var preview = document.querySelector("#blah");
                preview.setAttribute("src", "");
                var archivoSelect = document.querySelector("#anexoImg");
                archivoSelect.value = "";
              }}
            />
          </div>

          <Segment>
            <Header>Tareas</Header>
            <List>
              <List.Item>
                <List.Icon name="exclamation circle" />
                <List.Content>
                  <b>{myData.length} Acciones Totales</b>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="exclamation circle" color="red" />
                <List.Content>
                  <b>{myPac.length} Acciones Pendientes</b>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="exclamation circle" color="green" />
                <List.Content>
                  <b>
                    {100 - Math.round((myPac.length / myData.length) * 100, 2)}{" "}
                    % completada
                  </b>
                </List.Content>
              </List.Item>
            </List>
          </Segment>
          <Segment
            style={{
              height: "60vh",
              overflowY: "scroll",
            }}
          >
            {myPac && myPac.length !== 0 ? (
              <>
                <Header>{myPac[pagina - 1].rubro.toUpperCase()}</Header>
                <p>
                  {myPac[pagina - 1].categoria}
                  <br></br>
                  {myPac[pagina - 1].area}
                  <br></br>
                  Registrado por: {myPac[pagina - 1].nombre}
                  <br></br>
                  Hallazgo: {myPac[pagina - 1].observaciones}
                </p>
                <Header>Acciones Tomadas</Header>
                <p>{myPac[pagina - 1].pac}</p>
                <p>
                  Fecha Limite Inicial:{" "}
                  {FechaString(
                    ExcelDateToJSDate(myPac[pagina - 1].fecha_cierre)
                  )}
                  <br></br>
                  {myPac[pagina - 1].extension === "Y" ? (
                    <Checkbox
                      disabled
                      label={{ children: "Extender Fecha Limite " }}
                      checked
                    />
                  ) : (
                    <Checkbox
                      label={{ children: "Extender Fecha Limite " }}
                      onChange={() => {
                        if (extension !== "N") {
                          setExtension("N");
                        } else {
                          setExtension("Y");
                        }
                      }}
                      checked={extension === "Y"}
                    />
                  )}
                  <br></br>
                  {myPac[pagina - 1].extension === "Y" || extension === "Y" ? (
                    <Input
                      size="mini"
                      type="date"
                      onChange={(e) => {
                        setExtDate(e.target.value);
                      }}
                      defaultValue={
                        myPac[pagina - 1].extension === "Y"
                          ? xltoDate(
                              ExcelDateToJSDate(myPac[pagina - 1].nueva_fecha)
                            )
                          : date
                      }
                    />
                  ) : null}
                  <br></br>
                  <br></br>
                  Detalle Adicional
                  <br></br>
                  <Form>
                    <TextArea
                      id="pacDetail"
                      name="planDetail"
                      rows="4"
                      cols="60"
                      value={myPac[pagina - 1].detalle_pac}
                      onChange={(e, { value }) => {
                        setDetalle(value);
                      }}
                      placeholder="Detalle adicional de la acción tomada..."
                    />
                  </Form>
                </p>
                <p>
                  <Header>Acciones Preventivas</Header>
                  <Input
                    size="mini"
                    type="date"
                    onChange={(e) => {
                      setPreventDate(e.target.value);
                    }}
                    defaultValue={
                      myPac[pagina - 1].extension === "Y"
                        ? xltoDate(
                            ExcelDateToJSDate(myPac[pagina - 1].nueva_fecha)
                          )
                        : date
                    }
                  />
                  <br></br>
                  <br></br>
                  <Form>
                    <TextArea
                      id="ppreventiva"
                      name="ppreventiva"
                      rows="4"
                      cols="60"
                      onChange={(e, { value }) => {
                        setpap(value);
                      }}
                      placeholder="Detalle de acción preventiva..."
                    />
                  </Form>
                </p>
                <p>
                  <Header>Anexo</Header>
                  <Input
                    id="anexoImg"
                    fluid
                    className="input-image"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  <br />
                  <Image size="large" id="blah" src={previewImg} alt="Anexo" />
                </p>

                <div className="ui two buttons">
                  <Button color="yellow" onClick={registrar}>
                    Guardar
                  </Button>
                  <Button color="green">Solicitar Aprobacion</Button>
                </div>
              </>
            ) : null}
          </Segment>
        </>
      ) : (
        <Button
          color="red"
          inverted
          fluid
          icon="key"
          content="Sign In"
          onClick={() => {
            iniciar();
          }}
        />
      )}
    </Container>
  );
}

export default Tareas;
