import React, { useContext, useState } from "react";
import userData from "./employee.json";

export const Contexto = React.createContext(null);

export default ({ children }) => {
  let iniciado = null;
  if (sessionStorage.getItem("user")) {
    iniciado = sessionStorage.user;
  } else if (localStorage.getItem("user")) {
    iniciado = localStorage.user;
  }
  const [user, setUser] = useState(iniciado);
  const [recordar, setRecordar] = useState(false);
  const [data, setData] = useState();
  const [data1, setdata1] = useState();
  const [data2, setdata2] = useState();
  const [desviaciones, setDesviaciones] = useState();
  const [misReq, setMisReq] = useState();
  const [misTareas, setMisTareas] = useState();
  const [areas, setAreas] = useState();
  const [grupoArea, setGrupoArea] = useState();
  const [reportData, setRepData] = useState();
  const [pagina, setPagina] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [tipoReporte, setTipoReporte] = useState();
  const personal = [];
  for (var j = 0; j < userData.length; j++) {
    if (userData[j].Nombre !== "") {
      personal.push({
        key: userData[j].Nombre,
        text: userData[j].Nombre,
        value: userData[j].Nombre,
      });
    }
  }
  var breakpoint = 620;
  const color = [
    {
      categoria: "Calidad",
      activo: "#FF5300",
      inactivo: "#FF915C",
      texto: "white",
      caption: "Quality",
      short: "Q",
      gradient: "linear-gradient(to right,#fc4a1a,#f7b733)",
      cumple: 100,
    },
    {
      categoria: "Seguridad",
      activo: "#5A0FC4",
      inactivo: "#8857D1",
      texto: "white",
      caption: "Safety",
      short: "S",
      gradient: "linear-gradient(to right,#8E2DE2,#4A00E0)",
      cumple: 100,
    },
    {
      categoria: "Ambiente",
      activo: "#00C37E",
      inactivo: "#4ACFA0",
      texto: "white",
      caption: "Environment",
      short: "E",
      gradient: "linear-gradient(to right,#56ab2f,#a8e063)",
      cumple: 100,
    },
    {
      categoria: "Bioseguridad",
      activo: "#FFE700",
      inactivo: "#FFEF5C",
      texto: "black",
      caption: "BioS",
      short: "B",
      gradient: "linear-gradient(to right,#ffe259,#ffa751)",
      cumple: 100,
    },
  ];
  const [colores, setColores] = useState(color);
  const contexto = {
    user: [user, setUser],
    recordar: [recordar, setRecordar],
    data: [data, setData],
    areas: [areas, setAreas],
    grupoArea: [grupoArea, setGrupoArea],
    reportData: [reportData, setRepData],
    personal: personal,
    colores: [colores, setColores],
    color: color,
    pagina: [pagina, setPagina],
    width: [width, setWidth],
    data1: [data1, setdata1],
    data2: [data2, setdata2],
    desviaciones: [desviaciones, setDesviaciones],
    misReq: [misReq, setMisReq],
    misTareas: [misTareas, setMisTareas],
    tipoReporte: [tipoReporte, setTipoReporte],
    breakpoint: breakpoint,
    userData: userData,
  };

  return <Contexto.Provider value={contexto}>{children}</Contexto.Provider>;
};
