import React, { useState, useContext, useEffect, useRef } from "react";
import "./App.css";
import { useHistory } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { storage } from "./firebase/firebase";
import {
  List,
  Button,
  Image,
  Checkbox,
  Dimmer,
  Loader,
  Segment,
  Header,
  Label,
  Grid,
  Icon,
  Modal,
  Divider,
  Input,
  Form,
  TextArea,
  Dropdown,
  Progress,
  Container,
  Radio,
  Popup,
  Sticky,
} from "semantic-ui-react";
import { Contexto } from "./Contexto";
import { GoogleSpreadsheet } from "google-spreadsheet";
import imageCompression from "browser-image-compression";
import { GradientDefs } from "react-vis";

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25568);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  var date_data = new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );

  return date_data;
}

function FechaStringShort(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${ye}-${mo < 10 ? "0" + mo : mo}-${da}`;
  return fechaStr;
}

function FechaStringLong(fecha) {
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  var hora = new Intl.DateTimeFormat("en-GB", options).format(fecha);
  var fechaStr = hora;
  return fechaStr;
}

export default function ReporteDeTurno({ user }) {
  const referencia = useRef(null);
  const {
    ["reportData"]: [reportData, setRepData],
  } = useContext(Contexto);
  const {
    ["areas"]: [areas, setAreas],
  } = useContext(Contexto);
  const {
    ["pagina"]: [pagina, setPagina],
  } = useContext(Contexto);
  const [area, setArea] = useState();
  const [categoria, setCat] = useState();
  const { personal: personal } = useContext(Contexto);
  const {
    ["colores"]: [colores, setColores],
  } = useContext(Contexto);
  const [reportes, setReportes] = useState(); //Listado completo de CheckList
  const [sinFiltro, setSinFiltro] = useState();
  const [checkList, setCheckList] = useState();
  const [responsable, setResponsable] = useState();
  const [fecha, setFecha] = useState(FechaStringShort(new Date()));
  const [cats, setCats] = useState();
  const [rubros, setRubros] = useState();
  const [activeRubro, setActRubro] = useState();
  const [subCat, setSubCat] = useState();
  const [requerimientos, setReq] = useState();
  const [activeReq, setActiveR] = useState();
  const [incumple, setIncumple] = useState([]);
  const [percentage, setPercentage] = useState();
  const [turno, setTurno] = useState(1);
  const [campoOblig, setCampoOblig] = useState(0);
  const [confirmar, setConfirmar] = useState(false);
  const [duplicado, setDuplicado] = useState(false);
  const [listaArea, setListaArea] = useState();
  const [tipoReporte, setTipoRep] = useState("Reporte de Turno");
  const {
    ["width"]: [width, setWidth],
  } = useContext(Contexto);
  const { breakpoint: breakpoint } = useContext(Contexto);
  const { userData: userData } = useContext(Contexto);
  const dummyImg = "https://react.semantic-ui.com/images/wireframe/image.png";
  const [previewImg, setPreviewImg] = useState(dummyImg);
  const [imageAsFile, setImageAsFile] = useState("");
  const turnos = ["1", "2", "3"];
  const estados = [
    { name: "saving", caption: "Guardando" },
    { name: "loading", caption: "Cargando" },
    { name: "deleting", caption: "Borrando" },
  ];
  const [status, setStatus] = useState();
  const history = useHistory();

  async function handleImageUpload(event, rubro) {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      setReportes((existingItems) => {
        return existingItems.map((x) => {
          return x.SubCategoria === activeRubro && x.Categoria === activeReq
            ? { ...x, url: "", preview: "", img: "" }
            : x;
        });
      });
      console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      var reader = new FileReader();
      reader.onload = function (e) {
        setPreviewImg(e.target.result);
        setReportes((existingItems) => {
          return existingItems.map((x) => {
            return x.SubCategoria === activeRubro && x.Categoria === activeReq
              ? { ...x, preview: e.target.result }
              : x;
          });
        });
      };
      reader.readAsDataURL(event.target.files[0]);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB
        setImageAsFile((imageFile) => compressedFile);
        setReportes((existingItems) => {
          return existingItems.map((x) => {
            return x.SubCategoria === activeRubro && x.Categoria === activeReq
              ? { ...x, img: compressedFile }
              : x;
          });
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setImageAsFile("");
      setPreviewImg(dummyImg);
      setReportes((existingItems) => {
        return existingItems.map((x) => {
          return x.SubCategoria === activeRubro && x.Categoria === activeReq
            ? { ...x, preview: "" }
            : x;
        });
      });
    }
    setPercentage(0);
  }

  async function handleFireBaseUpload(rubro) {
    var boton = document.getElementById("btn" + activeReq + activeRubro);
    boton.disabled = true;
    setPercentage(1);
    const archivo =
      reportes[
        reportes.findIndex(
          (x) => x.SubCategoria === activeRubro && x.Categoria === activeReq
        )
      ].img;
    const uploadTask = storage.ref(`/images/${archivo.name}`).put(archivo);
    setPercentage(5);
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot);
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        setPercentage(50);
        storage
          .ref("images")
          .child(archivo.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setReportes((existingItems) => {
              return existingItems.map((x) => {
                return x.SubCategoria === activeRubro &&
                  x.Categoria === activeReq
                  ? { ...x, url: fireBaseUrl }
                  : x;
              });
            });
            setPercentage(100);
          });
      }
    );
  }

  const sacardata = async () => {
    var data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[1249329599];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    const encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    setRepData(datas);
  };
  const sacarReportes = async () => {
    setStatus(estados[1].caption);
    var data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets

    const sheetID = 1259390435;
    const sheet = doc.sheetsById[sheetID];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    const encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    datas.forEach((x) => {
      x.incumple = window.location.href.includes("recorrido") ? "" : "N";
      x.img = "";
      x.preview = "";
      x.url = "";
      x.personas = "";
      x.detalle = "";
      x.opcional = "N";
      x.turno = "";
      x.reporte = window.location.href.includes("recorrido")
        ? "Recorrido"
        : "Reporte de Turno";
    });
    console.log(datas);
    var aplican = datas.filter((x) => x["Reporte de turno"] === "Y");
    if (window.location.href.includes("recorrido")) {
      aplican = datas.filter((x) => x["Recorrido"] === "Y");
    }
    setSinFiltro(aplican);
    setReportes(aplican);
    setCats(
      aplican
        .map((item) => item.Tipo)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setStatus();
  };
  const sacarReportes2 = async () => {
    setStatus(estados[1].caption);
    var data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets

    const sheetID = window.location.href.includes("recorrido")
      ? 1236777776
      : 585241358;
    const sheet = doc.sheetsById[sheetID];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    const encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    datas.forEach((x) => {
      x.incumple = window.location.href.includes("recorrido") ? "" : "N";
      x.img = "";
      x.preview = "";
      x.url = "";
      x.personas = "";
      x.detalle = "";
      x.opcional = "N";
      x.turno = "";
      x.reporte = window.location.href.includes("recorrido")
        ? "Recorrido"
        : "Reporte de Turno";
    });
    console.log(datas);
    setReportes(datas);
    setCats(
      datas
        .map((item) => item.Tipo)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setStatus();
  };
  async function registrar() {
    setStatus(estados[0].caption);
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[1249329599];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    let lastr = rows.length;
    var variables = [area, fecha, user, tipoReporte];
    var uniqueID = variables.join("-");
    if (tipoReporte === "Reporte de Turno") {
      uniqueID = uniqueID + "-" + turno;
    }

    if (duplicado) {
      setStatus(estados[2].caption);
      while (lastr--) {
        try {
          if (rows[lastr].Id == uniqueID) {
            console.log("Borrando");
            await rows[lastr].delete();
          }
        } catch (err) {
          console.log(err);
          sleep(60000);
          lastr = lastr + 1;
          continue;
        }
      }
      setStatus(estados[0].caption);
    }
    var j;

    for (j = 0; j < reportes.length; j++) {
      try {
        console.log("guardando");
        await sheet.addRow({
          Area: area,
          Usuario: user,
          FechaRegistro: FechaStringLong(new Date()),
          FechaRecorrido: fecha,
          Tipo: reportes[j].Tipo,
          Categoria: reportes[j].Categoria,
          SubCategoria: reportes[j].SubCategoria,
          Incumple: reportes[j].incumple,
          Detalle: reportes[j].detalle,
          PersonasObservadas: reportes[j].personas,
          Imagen: reportes[j].url,
          Turno: turno,
          Reporte: tipoReporte,
          Id: uniqueID,
          Responsable: responsable,
        });
      } catch (err) {
        console.log(err);
        sleep(60000);
        j = j - 1;
        continue;
      }
    }
    alert("Datos Guardados");
    setActiveR();
    setStatus();
    setArea("");
    setActRubro();
    setRepData();
    setReportes();
    setResponsable();
    setConfirmar(false);
    history.push("/");
  }
  const [lista, setLista] = useState();
  const crearLista = (e, { name }) => {
    if (activeReq == name) {
      setActiveR();
    } else {
      setActiveR(name);
    }
    setLista(
      reportData
        .filter((item) => item.Nombre == name)
        .map((item) => item.Rubro)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
  };
  const revision = (e, { name, requerimiento }) => {
    let observacion = { req: requerimiento, rubro: name };
    if (
      reportData[reportData.findIndex((x) => x.Rubro === name)].Acto === "Y"
    ) {
      observacion.obligatorio = 1;
    }
    if (incumple.some((e) => e.req === requerimiento && e.rubro === name)) {
      let removeIndex = incumple.filter((e) => e.rubro !== name);
      console.log(removeIndex);
      // remove object
      setIncumple(removeIndex);
    } else {
      setIncumple((oldArray) => [...oldArray, observacion]);
    }
  };
  const validar = () => {
    var variables = [area, fecha, user, tipoReporte];
    var uniqueID = variables.join("-");
    if (tipoReporte === "Reporte de Turno") {
      uniqueID = uniqueID + "-" + turno;
    }
    let res = [];
    res = reportData.filter((element) => {
      return element.Id == uniqueID;
    });
    if (res.length > 0) {
      setDuplicado(true);
    } else {
      setDuplicado(false);
    }
  };

  const sacarAreas = async () => {
    var data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[1705686078];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    const encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    setAreas(datas);
  };
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  useEffect(() => {
    if (!areas) {
      sacarAreas();
    } else {
      var j;
      var userArea = userData.filter((x) => x.Nombre === user)[0].Area;
      var lastr = areas.length;
      const area = [];
      for (j = 0; j < lastr; j++) {
        let keys = areas[j].keys;
        if (
          areas[j].name !== "" &&
          (userArea.toLowerCase().includes("gerencia") ||
          window.location.href.includes("recorrido")
            ? true
            : keys
                .toLowerCase()
                .split(", ")
                .some((w) => userArea.toLowerCase().includes(w)))
        ) {
          area.push({
            key: areas[j].name,
            text: areas[j].name,
            value: areas[j].name,
          });
        }
      }
      setListaArea(area);
    }
  }, [areas]);

  useEffect(() => {
    if (turno && area) {
      if (!reportData) {
        sacardata();
      }
      validar();
    }
  }, [area, turno]);

  useEffect(() => {
    console.log(incumple);
  }, [incumple]);
  useEffect(() => {
    if (!reportData) {
      sacardata();
    }
  }, [reportData]);
  useEffect(() => {
    if (!reportes) {
      sacarReportes();
    } else {
    }
  }, []);
  useEffect(() => {
    setArea();
    setCat();
    setReportes();
    setRepData();
    setActiveR();
    setActRubro();
    sacarReportes();
    sacardata();
    var url = window.location.href;
    if ((pagina && pagina.includes("recorrido")) || url.includes("recorrido")) {
      setTipoRep("Reporte de Recorrido");
    } else {
      setTipoRep("Reporte de Turno");
    }
  }, [pagina]);
  function handleArea(e, { value }) {
    setCat();
    setActiveR();
    var filtro = sinFiltro.filter((x) => x[value] === "Y");
    setReportes(filtro);
    setArea(value);
  }

  function handleCategorias(e) {
    setCat(e.target.name);
    if (reportes) {
      var chklist = reportes.filter((item) => item.Tipo === e.target.name);
      setCheckList(chklist);
      setReq(
        chklist
          .map((item) => item.Categoria)
          .filter((value, index, self) => self.indexOf(value) === index)
      );
    }
  }
  function handleSubCat(e) {
    setSubCat(e.target.name);
    if (activeReq === e.target.name) {
      setActiveR();
    } else {
      setActiveR(e.target.name);
    }
    setRubros(
      reportes
        .filter((item) => item.Categoria === e.target.name)
        .map((item) => item.SubCategoria)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setActRubro();
  }

  function handleCheck(e) {
    var item = e.target.name;
    var chk = e.target.id;
    console.log(chk);
    setActRubro(item);
    var cumple;
    if (chk.includes("Y")) {
      cumple = "N";
      setReportes((existingItems) => {
        return existingItems.map((x) => {
          return x.SubCategoria === item && x.Categoria === activeReq
            ? {
                ...x,
                personas: "",
                detalle: "",
              }
            : x;
        });
      });
    } else {
      cumple = "Y";
    }
    setReportes((existingItems) => {
      return existingItems.map((x) => {
        return x.SubCategoria === item && x.Categoria === activeReq
          ? { ...x, incumple: cumple }
          : x;
      });
    });
  }

  function HandleTodos(e, { checked }) {
    var cumple;
    if (checked) {
      cumple = "N";
    } else {
      cumple = "";
    }
    if (activeReq) {
      setReportes((existingItems) => {
        return existingItems.map((x) => {
          return x.Categoria === activeReq ? { ...x, incumple: cumple } : x;
        });
      });
    } else {
      setReportes((existingItems) => {
        return existingItems.map((x) => {
          return x.Tipo === categoria ? { ...x, incumple: cumple } : x;
        });
      });
    }
  }

  function handleOpcional(e, { rubro }) {
    setActRubro(rubro);
    var aplica;
    if (
      reportes[
        reportes.findIndex(
          (x) => x.Categoria === activeReq && x.SubCategoria === rubro
        )
      ].opcional === "N"
    ) {
      aplica = "Y";
    } else {
      aplica = "N";

      setReportes((existingItems) => {
        return existingItems.map((x) => {
          return x.SubCategoria === rubro && x.Categoria === activeReq
            ? {
                ...x,
                personas: "",
              }
            : x;
        });
      });
    }
    setReportes((existingItems) => {
      return existingItems.map((x) => {
        return x.SubCategoria === rubro && x.Categoria === activeReq
          ? {
              ...x,
              opcional: aplica,
            }
          : x;
      });
    });
  }

  function handleObservados(e, { value }) {
    setReportes((existingItems) => {
      return existingItems.map((x) => {
        return x.SubCategoria === activeRubro && x.Categoria === activeReq
          ? {
              ...x,
              personas: value.toString(),
            }
          : x;
      });
    });
  }

  function handleDetalles(e, { value }) {
    setReportes((existingItems) => {
      return existingItems.map((x) => {
        return x.SubCategoria === activeRubro && x.Categoria === activeReq
          ? {
              ...x,
              detalle: value,
            }
          : x;
      });
    });
  }

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Dimmer
        active={
          status || estados.filter((x) => x.caption === status).length != 0
        }
        page
      >
        <Loader size="massive">{status}</Loader>
      </Dimmer>
      {reportes && (
        <Modal
          onClose={() => setConfirmar(false)}
          onOpen={() => setConfirmar(true)}
          size="large"
          open={confirmar}
        >
          <Modal.Header>Confirmar Información</Modal.Header>
          <Modal.Content>
            {duplicado && (
              <Label basic color="red" size="huge">
                <Icon name="warning sign" />
                Se sobreescribirá los datos
              </Label>
            )}
            <h1>
              {tipoReporte.includes("Recorrido")
                ? tipoReporte
                : tipoReporte + " " + turno}
            </h1>
            <h2>{fecha}</h2>
            <h3>Realizado por: {user}</h3>
            <h3>En conjunto a: {responsable}</h3>
            <h4>{area}</h4>
          </Modal.Content>

          <Divider />
          <Modal.Content>
            {reportes.filter((x) => x.incumple === "Y").length > 0 && (
              <b>Incumplimientos: </b>
            )}
            {reportes.filter((x) => x.incumple === "Y").length > 0 && (
              <List>
                {reportes
                  .filter((x) => x.incumple === "Y")
                  .map((item, index) => {
                    return (
                      <List.Item>
                        {index + 1}. {item.Tipo} - {item.Categoria} -{" "}
                        {item.SubCategoria}
                      </List.Item>
                    );
                  })}
              </List>
            )}
            {reportes.filter((x) => x.incumple === "Y").length == 0 && (
              <b>Sin Incidencias</b>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="green"
              onClick={() => {
                registrar();
              }}
            >
              <Icon name="check"></Icon>Confirmar
            </Button>
            <Button
              secondary
              color="red"
              onClick={() => {
                setConfirmar(false);
              }}
            >
              <Icon name="x"></Icon>Cancelar
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      <Grid.Row>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Segment basic>
            <Header>{tipoReporte}</Header>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <b>Fecha*</b>
          <Input
            fluid
            type="date"
            defaultValue={fecha}
            onChange={(e, { value }) => {
              setFecha(value);
            }}
          ></Input>
        </Grid.Column>
      </Grid.Row>
      {reportes && (
        <Grid.Row>
          <Grid.Column>
            <b>Area*</b>
            <Dropdown
              style={{ textAlign: "center" }}
              fluid
              search
              selection
              placeholder="Area..."
              options={listaArea}
              onChange={handleArea}
            ></Dropdown>
          </Grid.Column>
        </Grid.Row>
      )}
      {reportes && (
        <Grid.Row>
          <Grid.Column>
            <b>Responsable del Area*</b>
            <Dropdown
              fluid
              search
              selection
              placeholder="Seleccionar Personas que acompaña..."
              options={personal}
              onChange={(e, { value }) => {
                setResponsable(value);
              }}
            ></Dropdown>
          </Grid.Column>
        </Grid.Row>
      )}

      {area && tipoReporte.includes("Turno") && (
        <Grid.Row>
          <Grid.Column>
            <b>Turno*</b>
            <Form>
              <Grid columns={3}>
                <Grid.Row>
                  {turnos.map((x) => {
                    return (
                      <Grid.Column>
                        <Radio
                          label={x}
                          checked={turno === x}
                          onChange={() => {
                            setTurno(x);
                          }}
                        ></Radio>
                      </Grid.Column>
                    );
                  })}
                </Grid.Row>
              </Grid>
            </Form>
          </Grid.Column>
        </Grid.Row>
      )}
      {duplicado && area && turno && (
        <Grid.Row>
          <Grid.Column>
            <Label basic color="red">
              <Icon name="warning sign" />
              Ud. ya ha registrado un {tipoReporte} para {area}{" "}
              {tipoReporte === "Reporte de Turno" ? "del turno " + turno : ""}{" "}
              para el dia {fecha}, en caso que guardes se sobreescribira el
              reporte anterior
            </Label>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row columns={4}>
        {cats &&
          turno &&
          area &&
          cats.map((item) => {
            return (
              <Grid.Column>
                <Button
                  fluid
                  style={{
                    backgroundColor:
                      categoria === item
                        ? colores.filter((x) => {
                            return x.categoria === item;
                          })[0].activo
                        : colores.filter((x) => {
                            return x.categoria === item;
                          })[0].inactivo,
                    color: colores.filter((x) => {
                      return x.categoria === item;
                    })[0].texto,
                  }}
                  name={item}
                  onClick={handleCategorias}
                >
                  {width > breakpoint
                    ? colores.filter((x) => {
                        return x.categoria === item;
                      })[0].caption
                    : colores.filter((x) => {
                        return x.categoria === item;
                      })[0].short}
                  <Popup
                    content="Cantidad de incumplimiento"
                    trigger={
                      <Label
                        style={{ left: "90%" }}
                        color={
                          reportes &&
                          reportes.filter(
                            (x) => x.Tipo === item && x.incumple === "Y"
                          ).length > 0
                            ? "red"
                            : "grey"
                        }
                        floating
                        circular
                        size="mini"
                      >
                        {reportes &&
                          reportes.filter(
                            (x) => x.Tipo === item && x.incumple === "Y"
                          ).length}
                      </Label>
                    }
                  />
                </Button>
              </Grid.Column>
            );
          })}
      </Grid.Row>
      {reportes && (
        <Grid.Row>
          <Grid.Column>
            <Button
              disabled={
                !area |
                !turno |
                (reportes &&
                  reportes.filter(
                    (x) =>
                      x.incumple === "Y" &&
                      (x.Acto === "Y" || x.opcional === "Y") &&
                      x.personas === ""
                  ).length > 0) |
                (reportes.filter((x) => x.incumple === "").length > 0)
              }
              fluid
              color="green"
              icon="save"
              content="Guardar"
              onClick={() => setConfirmar(true)}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      {reportes &&
        reportes.filter(
          (x) =>
            x.incumple === "Y" &&
            (x.Acto === "Y" || x.opcional === "Y") &&
            x.personas === ""
        ).length > 0 && (
          <Grid.Row>
            <Label basic color="red">
              <Icon name="warning sign" /> Campo obligatorio sin completar
            </Label>
          </Grid.Row>
        )}
      {categoria && area && reportes && (
        <Grid.Row>
          <Grid.Column>
            <Segment
              fluid
              basic
              style={{
                color: colores.filter((x) => {
                  return x.categoria === categoria;
                })[0].activo,
              }}
            >
              <Header
                onClick={() => {
                  console.log(reportes);
                  console.log(activeReq);
                  console.log(activeRubro);
                }}
              >
                {categoria}
                {" Cumplimiento "}
                {Math.ceil(
                  (reportes.filter(
                    (x) => x.Tipo === categoria && x.incumple === "N"
                  ).length /
                    reportes.filter((x) => x.Tipo === categoria).length) *
                    100
                )}
                %
              </Header>
              <Checkbox
                key={categoria + "AllY"}
                label={"Todos Cumplen"}
                checked={
                  reportes.filter((x) => x.Tipo === categoria).length ==
                  reportes.filter(
                    (x) => x.Tipo === categoria && x.incumple === "N"
                  ).length
                }
                onChange={HandleTodos}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      )}
      {requerimientos &&
        categoria &&
        area &&
        reportes &&
        requerimientos.map((item, reqIdx) => {
          return (
            <Grid.Row>
              <Grid.Column>
                <Button
                  fluid
                  style={{
                    backgroundColor: colores.filter((item) => {
                      return item.categoria === categoria;
                    })[0].inactivo,
                    color: colores.filter((item) => {
                      return item.categoria === categoria;
                    })[0].texto,
                  }}
                  name={item}
                  onClick={handleSubCat}
                >
                  {item}
                </Button>
                {activeReq === item && (
                  <Segment basic>
                    <Grid verticalAlign="left">
                      <Checkbox
                        key={reqIdx + "AllY"}
                        requerimiento={activeReq}
                        label={"Todos Cumplen"}
                        checked={
                          reportes.filter((x) => x.Categoria === activeReq)
                            .length ==
                          reportes.filter(
                            (x) =>
                              x.Categoria === activeReq && x.incumple === "N"
                          ).length
                        }
                        onChange={HandleTodos}
                      />
                    </Grid>
                    <Grid verticalAlign="left">
                      {rubros &&
                        rubros.map((item, idx) => {
                          return (
                            <Grid.Row>
                              <Grid.Column>
                                {idx + 1 + ". " + item}
                                <br />
                                <Grid.Row columns={3}>
                                  <Grid.Column>Cumple?</Grid.Column>
                                  <Grid.Column>
                                    <Checkbox
                                      fluid
                                      key={idx + "Y"}
                                      id={idx + "Y"}
                                      name={item}
                                      requerimiento={activeReq}
                                      label={"Si"}
                                      checked={
                                        reportes.filter(
                                          (x) =>
                                            x.SubCategoria === item &&
                                            x.Categoria === activeReq
                                        )[0].incumple === "N"
                                      }
                                      onChange={handleCheck}
                                    />
                                  </Grid.Column>
                                  <br />
                                  <Grid.Column>
                                    <Checkbox
                                      fluid
                                      key={idx + "N"}
                                      id={idx + "N"}
                                      name={item}
                                      requerimiento={activeReq}
                                      label={"No"}
                                      checked={
                                        reportes.filter(
                                          (x) =>
                                            x.SubCategoria === item &&
                                            x.Categoria === activeReq
                                        )[0].incumple === "Y"
                                      }
                                      onChange={handleCheck}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                {reportes.filter(
                                  (x) =>
                                    x.SubCategoria === item &&
                                    x.Categoria === activeReq
                                )[0].incumple === "Y" && (
                                  <Segment color="red">
                                    <Grid>
                                      {reportes.filter(
                                        (x) =>
                                          x.SubCategoria === item &&
                                          x.Categoria === activeReq
                                      )[0].incumple === "Y" &&
                                        reportes.filter(
                                          (x) =>
                                            x.SubCategoria === item &&
                                            x.Categoria === activeReq
                                        )[0].Acto === "N" && (
                                          <Grid.Row>
                                            <Grid.Column>
                                              <Checkbox
                                                fluid
                                                requerimiento={activeReq}
                                                rubro={item}
                                                label="Personas Observadas?"
                                                checked={
                                                  reportes.filter(
                                                    (x) =>
                                                      x.SubCategoria === item &&
                                                      x.Categoria === activeReq
                                                  )[0].opcional === "Y"
                                                }
                                                onChange={handleOpcional}
                                              />
                                            </Grid.Column>
                                          </Grid.Row>
                                        )}
                                      {reportes.filter(
                                        (x) =>
                                          x.SubCategoria === item &&
                                          x.Categoria === activeReq
                                      )[0].incumple === "Y" &&
                                        (reportes.filter(
                                          (x) =>
                                            x.SubCategoria === item &&
                                            x.Categoria === activeReq
                                        )[0].Acto === "Y" ||
                                          reportes.filter(
                                            (x) =>
                                              x.SubCategoria === item &&
                                              x.Categoria === activeReq
                                          )[0].opcional === "Y") && (
                                          <Grid.Row>
                                            <Grid.Column>
                                              <b>Personas Observadas*</b>
                                              <Dropdown
                                                fluid
                                                multiple
                                                search
                                                selection
                                                defaultValue={
                                                  reportes[
                                                    reportes.findIndex(
                                                      (x) =>
                                                        x.Categoria ===
                                                          activeReq &&
                                                        x.SubCategoria === item
                                                    )
                                                  ].personas
                                                    ? reportes[
                                                        reportes.findIndex(
                                                          (x) =>
                                                            x.Categoria ===
                                                              activeReq &&
                                                            x.SubCategoria ===
                                                              item
                                                        )
                                                      ].personas.split(",")
                                                    : ""
                                                }
                                                placeholder="Seleccionar Personas Involucradas..."
                                                options={personal}
                                                onChange={handleObservados}
                                              ></Dropdown>
                                              {!reportes[
                                                reportes.findIndex(
                                                  (x) =>
                                                    x.Categoria === activeReq &&
                                                    x.SubCategoria === item
                                                )
                                              ].personas && (
                                                <Label
                                                  basic
                                                  color="red"
                                                  pointing
                                                >
                                                  <Icon name="warning sign" />
                                                  Por favor elija al menos una
                                                  persona
                                                </Label>
                                              )}
                                            </Grid.Column>
                                          </Grid.Row>
                                        )}
                                      <Grid.Row>
                                        <Grid.Column>
                                          <b>Detalles</b>
                                          <Form>
                                            <TextArea
                                              placeholder="Detelles..."
                                              defaultValue={
                                                reportes[
                                                  reportes.findIndex(
                                                    (x) =>
                                                      x.Categoria ===
                                                        activeReq &&
                                                      x.SubCategoria === item
                                                  )
                                                ].detalle
                                                  ? reportes[
                                                      reportes.findIndex(
                                                        (x) =>
                                                          x.Categoria ===
                                                            activeReq &&
                                                          x.SubCategoria ===
                                                            item
                                                      )
                                                    ].detalle
                                                  : ""
                                              }
                                              onChange={handleDetalles}
                                            ></TextArea>
                                          </Form>
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column>
                                          <b>Imagen</b>
                                          <Input
                                            icon="camera"
                                            iconPosition="left"
                                            type="file"
                                            fluid
                                            rubro={item}
                                            onChange={(e, { rubro }) => {
                                              handleImageUpload(e, rubro);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      {reportes[
                                        reportes.findIndex(
                                          (x) =>
                                            x.SubCategoria === item &&
                                            x.Categoria === activeReq
                                        )
                                      ].preview &&
                                        percentage == 0 && (
                                          <Grid.Row>
                                            <Grid.Column>
                                              <Button
                                                id={"btn" + activeReq + item}
                                                rubro={item}
                                                content="Confirmar"
                                                style={{
                                                  backgroundColor:
                                                    colores.filter((item) => {
                                                      return (
                                                        item.categoria ===
                                                        categoria
                                                      );
                                                    })[0].inactivo,
                                                  color: colores.filter(
                                                    (item) => {
                                                      return (
                                                        item.categoria ===
                                                        categoria
                                                      );
                                                    }
                                                  )[0].texto,
                                                }}
                                                onClick={(e, { rubro }) => {
                                                  handleFireBaseUpload(rubro);
                                                }}
                                              />
                                            </Grid.Column>
                                          </Grid.Row>
                                        )}
                                      {reportes[
                                        reportes.findIndex(
                                          (x) =>
                                            x.SubCategoria === item &&
                                            x.Categoria === activeReq
                                        )
                                      ].preview && (
                                        <Grid.Row>
                                          <Grid.Column>
                                            <Progress
                                              percent={
                                                reportes[
                                                  reportes.findIndex(
                                                    (x) =>
                                                      x.SubCategoria === item &&
                                                      x.Categoria === activeReq
                                                  )
                                                ].url
                                                  ? 100
                                                  : percentage
                                              }
                                              indicating={
                                                !reportes[
                                                  reportes.findIndex(
                                                    (x) =>
                                                      x.SubCategoria === item &&
                                                      x.Categoria === activeReq
                                                  )
                                                ].url && percentage != 100
                                              }
                                              success={
                                                reportes[
                                                  reportes.findIndex(
                                                    (x) =>
                                                      x.SubCategoria === item &&
                                                      x.Categoria === activeReq
                                                  )
                                                ].url
                                                  ? true
                                                  : percentage == 100
                                              }
                                              progress
                                              label={
                                                reportes[
                                                  reportes.findIndex(
                                                    (x) =>
                                                      x.SubCategoria === item &&
                                                      x.Categoria === activeReq
                                                  )
                                                ].url || percentage == 100
                                                  ? "Cargado"
                                                  : percentage == 0
                                                  ? ""
                                                  : "Cargando"
                                              }
                                            />
                                          </Grid.Column>
                                        </Grid.Row>
                                      )}
                                      {reportes[
                                        reportes.findIndex(
                                          (x) =>
                                            x.SubCategoria === item &&
                                            x.Categoria === activeReq
                                        )
                                      ].preview && (
                                        <Grid.Row>
                                          <Grid.Column>
                                            <Image
                                              size="medium"
                                              src={
                                                reportes[
                                                  reportes.findIndex(
                                                    (x) =>
                                                      x.SubCategoria === item &&
                                                      x.Categoria === activeReq
                                                  )
                                                ].preview
                                              }
                                            />
                                          </Grid.Column>
                                        </Grid.Row>
                                      )}
                                    </Grid>
                                  </Segment>
                                )}
                              </Grid.Column>
                            </Grid.Row>
                          );
                        })}
                    </Grid>
                  </Segment>
                )}
              </Grid.Column>
            </Grid.Row>
          );
        })}
    </Grid>
  );
}
