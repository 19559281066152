import React, { useState, useEffect, useRef, useContext } from "react";
import "semantic-ui-css/semantic.min.css";
import "date-fns";
import "./App.css";
import emailjs from "emailjs-com";
import apiKeys from "./apikeys";
import { storage } from "./firebase/firebase";
import { useHistory } from "react-router-dom";
import {
  Container,
  Button,
  Popup,
  Image,
  Input,
  Icon,
  TextArea,
  Form,
  Segment,
  Dropdown,
  Step,
  Header,
  Card,
  Rating,
  Ref,
  Dimmer,
  Loader,
  Modal,
} from "semantic-ui-react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import "react-datepicker/dist/react-datepicker.css";
import imageCompression from "browser-image-compression";
import userData from "./employee.json";
import { Contexto } from "./Contexto";

function FechaString(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${ye}-${mo < 10 ? "0" + mo : mo}-${da}`;
  return fechaStr;
}

function Registros({ user }) {
  //Fechas
  let today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var hora = today.getHours();
  var minutos = today.getMinutes();
  dd < 10 ? (dd = "0" + dd) : (dd = today.getDate());
  mm < 10 ? (mm = "0" + mm) : (mm = today.getMonth() + 1);
  if (hora < 10) hora = "0" + hora;
  if (minutos < 10) minutos = "0" + minutos;
  let date = yyyy + "-" + mm + "-" + dd;
  let fecha = date + " " + hora + ":" + minutos;
  //Fechas
  const dummyImg = "https://react.semantic-ui.com/images/wireframe/image.png";
  const [idUnico, setID] = useState();
  const [loading, setLoading] = useState(true);
  const [perfil, setPerfil] = useState();
  const [correo, setCorreo] = useState("No Aplica");
  const [creador, setCreador] = useState();
  const [step, setStep] = useState("Que");
  const [tipo, setTipo] = useState("");
  const [cat, setCat] = useState("");
  const [data, setData] = useState("");
  const [data2, setData2] = useState("");
  const [catData, setCatData] = useState("");
  const [subCatData, setSubCatData] = useState("");
  const [subCat, setSubCat] = useState("");
  const [detalles, setDetalles] = useState("");
  const [area, setArea] = useState("");
  const [que, setQue] = useState(false);
  const [donde, setDonde] = useState(false);
  const [pacStep, setPacStep] = useState(false);
  const [involucrados, setInvolucrados] = useState([]);
  const [plan, setPlan] = useState("");
  const [startDate, setStartDate] = useState(date);
  const [resp, setResp] = useState("");
  const [saving, setSaving] = useState(false);
  const segCat = useRef(null);
  const segSubCat = useRef(null);
  const allInputs = { imgUrl: "" };
  const [previewImg, setPreviewImg] = useState(dummyImg);
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const [subir, setSubir] = useState(false);
  const [guardar, setGuardar] = useState(false);
  const [confirmar, setConfirmar] = useState(false);
  const [severidad, setSeveridad] = useState(1);
  const [imgUpload, setImgUpload] = useState();

  const history = useHistory();

  const tipos = [
    { id: "Q", name: "Calidad" },
    { id: "S", name: "Seguridad" },
    { id: "E", name: "Ambiente" },
    { id: "B", name: "Bioseguridad" },
  ];
  const {
    ["areas"]: [areas, setAreas],
  } = useContext(Contexto);

  async function handleImageUpload(event) {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      var reader = new FileReader();
      var preview = document.querySelector("#blah");
      console.log(preview);
      reader.onload = function (e) {
        setPreviewImg(e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB
        setImageAsFile((imageFile) => compressedFile);
      } catch (error) {
        console.log(error);
      }
      setSubir(true);
    } else {
      setSubir(false);
      setImageAsFile("");
      setImageAsUrl("");
      setPreviewImg(dummyImg);
      var archivoSelect = document.querySelector("#anexoImg");
      console.log(archivoSelect);
      archivoSelect.value = "";
    }
  }

  async function handleFireBaseUpload() {
    if (subir) {
      console.log("start of upload");
      console.log(imageAsFile);
      if (imageAsFile === "") {
        console.error(
          `not an image, the image file is a ${typeof imageAsFile}`
        );
      } else {
        const uploadTask = storage
          .ref(`/images/${imageAsFile.name}`)
          .put(imageAsFile);
        uploadTask.on(
          "state_changed",
          (snapShot) => {
            //takes a snap shot of the process as it is happening
            console.log(snapShot);
          },
          (err) => {
            //catches the errors
            console.log(err);
          },
          () => {
            // gets the functions from storage refences the image storage in firebase by the children
            // gets the download url then sets the image from firebase as the value for the imgUrl key:
            storage
              .ref("images")
              .child(imageAsFile.name)
              .getDownloadURL()
              .then((fireBaseUrl) => {
                setImageAsUrl((prevObject) => ({
                  ...prevObject,
                  imgUrl: fireBaseUrl,
                }));
                setImgUpload(fireBaseUrl);
              });
          }
        );
      }
    }
  }

  var sheetNo = 2125477317;
  var sheetNo2 = 715064374;

  const sacardata = async () => {
    var datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[sheetNo];
    const rows = await sheet.getRows();
    let lastr = rows.length + 1;
    var j;
    await sheet.loadCells("A1:E" + lastr.toString());
    for (j = 1; j < lastr; j++) {
      const ar = sheet.getCell(j, 0);
      const br = sheet.getCell(j, 1);
      const cr = sheet.getCell(j, 4);
      datas.push({
        Tipo: ar.value,
        Categoria: br.value,
        src: cr.value,
      });
    }
    setData(datas);
    var datas2 = [];
    const sheet2 = doc.sheetsById[sheetNo2];
    const rows2 = await sheet2.getRows();
    lastr = rows2.length + 1;
    await sheet2.loadCells("A1:C" + lastr.toString());
    for (j = 1; j < lastr; j++) {
      const ar = sheet2.getCell(j, 0);
      const br = sheet2.getCell(j, 1);
      const cr = sheet2.getCell(j, 2);
      datas2.push({
        Tipo: ar.value,
        Categoria: br.value,
        SubCategoria: cr.value,
        key: cr.value,
        text: cr.value,
        value: cr.value,
      });
    }
    setData2(datas2);
  };

  const handleTipoClick = (e) => {
    if (tipo === e.target.name) {
      setTipo("");
    } else {
      setTipo(e.target.name);
      console.log(e.target.name);
      segCat.current.focus();
      setCat("");
      setSubCat("");
      setSubCatData("");
    }
  };

  var j;
  var lastr = userData.length;
  const personal = [];
  for (j = 0; j < lastr; j++) {
    if (userData[j].Nombre !== "") {
      personal.push({
        key: userData[j].Nombre,
        text: userData[j].Nombre,
        value: userData[j].Nombre,
      });
    }
  }
  const sacarAreas = async () => {
    var data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[1705686078];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    const encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    setAreas(datas);
  };

  useEffect(() => {
    if (!areas) {
      sacarAreas();
    }
  }, [areas]);
  useEffect(() => {
    if (user) {
      const datosUsuario = userData.filter((item) => {
        return item.Nombre === user;
      });
      setPerfil(datosUsuario[0].Perfil);
      setCreador(user);
    }
  }, [user]);

  useEffect(() => {
    if (resp) {
      const datoResp = userData.filter((item) => {
        return item.Nombre === resp;
      });
      setCorreo(datoResp[0].correo);
    }
  }, [resp]);

  const handleCatClick = (e, { name }) => {
    setSubCat("");
    setSubCatData("");
    setCat(name);
    console.log(name);
  };

  const handleStep = (e, { name }) => {
    setStep(name);
    console.log(name);
  };

  const handleArea = (e, { name }) => {
    setArea(name);
    console.log(name);
  };

  const handleSave = () => {
    setConfirmar(true);
  };

  async function registrar() {
    setLoading(true);
    let wd = today.getDay();
    wd === 0 ? (wd = 7) : (wd = wd);
    let semana = FechaString(today.setDate(today.getDate() - wd + 1));
    const creds = require("./sasso-app.json"); // the file saved above
    const aplica = perfil === "Registros" ? "Y" : "N";
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[0];
    var j;
    var UniqueID =
      Date.now() +
      user.substring(0, 2) +
      tipo.substring(0, 1) +
      subCat.substring(0, 2);
    setID(UniqueID);
    console.log(UniqueID);
    for (j = 0; j < involucrados.length; j++) {
      await sheet.addRow({
        fecha: date,
        fecha_hora: fecha,
        nombre: creador,
        area: area,
        rubro: tipo,
        categoria: cat,
        observaciones: subCat,
        pac: plan,
        responsable: resp,
        fecha_cierre: startDate,
        anexo: imgUpload,
        status: "Pendiente",
        Personas_Observadas: involucrados[j],
        id: UniqueID,
        severidad: severidad,
        Detalle: detalles,
        Aplica: aplica,
        semana: semana,
        ids: 1 / involucrados.length,
      });
    }
    if (correo !== "No Aplica") {
      notificar(UniqueID);
    }
    setTipo("");
    setCat("");
    setSubCat("");
    setDetalles("");
    setInvolucrados([]);
    setArea("");
    setPlan("");
    setResp("");
    setImageAsUrl("");
    setPreviewImg("");
    setLoading(false);
    setConfirmar(false);
    setSaving(true);
  }

  function notificar(codigo) {
    var templateParams = {
      name: resp,
      to_email: correo,
      fecha_limite: startDate,
      lugar: area,
      qseb: tipo,
      categoria: cat,
      observacion: subCat,
      detalles: detalles,
      imagen: "https://www.miculturaqse507.com/sasso-app/observacion/" + codigo,
      involucrados: involucrados.join(),
      pac: plan,
      registrado: creador,
    };

    emailjs
      .send("Gmail", apiKeys.TEMPLATE_ID, templateParams, apiKeys.USER_ID)
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    setID("");
  }

  const iniciar = () => {
    var priceEls = document.querySelectorAll(".item");
    for (var i = 0; i < priceEls.length; i++) {
      var ele = priceEls[i].innerText;
      if (ele === "Usuario") {
        priceEls[i].click();
        break;
      }
    }
  };

  useEffect(() => {
    if (!data) {
      sacardata();
    }
    if (data) {
      setLoading(false);
    }
    if (tipo) {
      setCatData(
        data.filter((item) => {
          return item.Tipo === tipo;
        })
      );
    }
    if (cat) {
      setSubCatData(
        data2.filter((item) => {
          return item.Categoria === cat;
        })
      );
    }
    if (tipo && cat && subCat && involucrados.length !== 0) {
      setQue(true);
    } else {
      setQue(false);
    }
    if (area) {
      setDonde(true);
    } else {
      setDonde(false);
    }
    if (plan && resp && startDate) {
      setPacStep(true);
    } else {
      setPacStep(false);
    }
    if (
      tipo &&
      cat &&
      subCat &&
      involucrados.length !== 0 &&
      area &&
      plan &&
      resp &&
      startDate
    ) {
      setGuardar(true);
    } else {
      setGuardar(false);
    }
  }, [
    data,
    tipo,
    cat,
    subCat,
    involucrados,
    area,
    plan,
    resp,
    startDate,
    data2,
  ]);

  useEffect(() => {
    if (subir) {
      handleFireBaseUpload();
    }
  }, [subir]);

  return (
    <div>
      {user ? (
        <div>
          <Dimmer active={loading} page>
            <Loader size="massive">{data ? "Saving" : "Loading"}</Loader>
          </Dimmer>
          <Modal
            header="Listo!"
            content="Su observación ha sido registrado exitosamente."
            open={saving}
            actions={[
              {
                key: "done",
                content: "Done",
                positive: true,
                onClick: () => {
                  setSaving(false);
                  history.push("/observaciones");
                },
              },
            ]}
          />

          <Modal
            onClose={() => setConfirmar(false)}
            onOpen={() => setConfirmar(true)}
            open={confirmar}
          >
            <Modal.Header>Confirmar Información</Modal.Header>
            <Modal.Content image>
              <Image size="medium" src={previewImg} wrapped />
              <Modal.Description>
                <Header>Observacion de {tipo}</Header>
                <p>{cat}</p>
                <p>{subCat}</p>
                <p>Severidad:{severidad}</p>
                <Header>Persona(s) Observada(s)</Header>
                {involucrados.map((item) => (
                  <li>{item}</li>
                ))}
                <Header>Area</Header>
                <p>{area}</p>
                <Header>PLAN DE ACCION</Header>
                <p>{plan}</p>
                <p>Responsable: {resp}</p>
                <p>Fecha de Cierre Previsto: {startDate}</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={() => setConfirmar(false)}>
                Cancelar
              </Button>
              <Button
                content="Confirmar"
                labelPosition="right"
                icon="checkmark"
                onClick={() => {
                  registrar();
                }}
                positive
              />
            </Modal.Actions>
          </Modal>
          {guardar ? (
            <Button
              fluid
              color="green"
              content="Guardar"
              onClick={handleSave}
            ></Button>
          ) : null}
          <Step.Group ordered size="mini" attached="top" unstackable>
            <Step
              active={step === "Que"}
              name="Que"
              onClick={handleStep}
              completed={que}
            >
              <Step.Content>
                <Step.Title>Observación</Step.Title>
              </Step.Content>
            </Step>

            <Step
              active={step === "Donde"}
              name="Donde"
              onClick={handleStep}
              completed={donde}
            >
              <Step.Content>
                <Step.Title>Area</Step.Title>
              </Step.Content>
            </Step>

            <Step
              active={step === "PAC"}
              name="PAC"
              onClick={handleStep}
              completed={pacStep}
            >
              <Step.Content>
                <Step.Title>PAC</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
          <Segment attached>
            {step === "Que" ? (
              <Container>
                {user === "user" && (
                  <>
                    <Segment>
                      <Header>Nombre del Observador</Header>
                      <Input
                        fluid
                        placeholder="Nombre del Observador..."
                        onChange={(e, { value }) => {
                          setCreador(value);
                        }}
                      />
                    </Segment>
                  </>
                )}
                <Segment name="segmentTipo">
                  <Header>Tipo de Observación</Header>
                  {tipos.map((item) => (
                    <Popup
                      trigger={
                        <Button
                          color="red"
                          inverted
                          circular
                          name={item.name}
                          onClick={handleTipoClick}
                          active={tipo === item.name}
                        >
                          {item.id}
                        </Button>
                      }
                    >
                      <Popup.Header>{item.name}</Popup.Header>
                    </Popup>
                  ))}
                </Segment>
                <Segment name="segmentInvol">
                  <Header>Personas Responsables/Involucradas</Header>
                  <Dropdown
                    fluid
                    multiple
                    search
                    selection
                    value={involucrados}
                    placeholder="Seleccionar Personas Involucradas"
                    options={personal}
                    onChange={(e, { value }) => {
                      setInvolucrados(value);
                      console.log(value);
                    }}
                  />
                </Segment>
                <Ref innerRef={segCat}>
                  <Segment name="segmentCat">
                    <Header>Categoria de la Observación</Header>
                    <Card.Group itemsPerRow={4}>
                      {catData
                        ? catData.map((item) => (
                            <Popup
                              trigger={
                                <Card
                                  raised
                                  color={
                                    cat === item.Categoria ? "red" : "black"
                                  }
                                  name={item.Categoria}
                                  onClick={handleCatClick}
                                >
                                  <Image
                                    src={item.src}
                                    size="mini"
                                    wrapped
                                    ui={false}
                                  />
                                  <Card.Content>
                                    {cat === item.Categoria ? (
                                      <Icon
                                        color="green"
                                        name="check circle"
                                      ></Icon>
                                    ) : null}
                                  </Card.Content>
                                </Card>
                              }
                            >
                              <Popup.Header>{item.Categoria}</Popup.Header>
                            </Popup>
                          ))
                        : null}
                    </Card.Group>
                  </Segment>
                </Ref>

                {subCatData ? (
                  <Ref innerRef={segSubCat}>
                    <Segment name="segmentSubCat">
                      <Header>Observación</Header>
                      <Dropdown
                        fluid
                        button
                        search
                        scrolling
                        clearable
                        value={subCat}
                        placeholder="Seleccionar Observacion"
                        options={subCatData}
                        onChange={(e, { value }) => {
                          setSubCat(value);
                        }}
                      />
                      <br />
                      <Header>
                        Severidad
                        <Popup
                          trigger={
                            <div>
                              <Icon
                                name="times circle"
                                color={severidad > 0 ? "red" : "white"}
                                onClick={() => {
                                  setSeveridad(1);
                                }}
                              ></Icon>
                              <Icon
                                name="times circle"
                                color={severidad > 1 ? "red" : "white"}
                                onClick={() => {
                                  setSeveridad(2);
                                }}
                              ></Icon>
                              <Icon
                                name="times circle"
                                color={severidad > 2 ? "red" : "white"}
                                onClick={() => {
                                  setSeveridad(3);
                                }}
                              ></Icon>
                            </div>
                          }
                          content={"Grado de Severidad: " + severidad}
                        ></Popup>
                      </Header>
                      <Form>
                        <TextArea
                          placeholder="Detalles"
                          onChange={(e, { value }) => {
                            setDetalles(value);
                          }}
                        ></TextArea>
                      </Form>
                    </Segment>
                  </Ref>
                ) : null}
              </Container>
            ) : null}
            {step === "Donde" && areas ? (
              <div>
                <Segment>
                  <Header>Área de la Observación</Header>
                  {areas.map((item) => (
                    <Popup
                      trigger={
                        <Button
                          color="red"
                          inverted
                          style={{ width: "15vw" }}
                          name={item.name}
                          onClick={handleArea}
                          active={area === item.name}
                        >
                          {item.short}
                        </Button>
                      }
                    >
                      <Popup.Header>{item.name}</Popup.Header>
                    </Popup>
                  ))}
                </Segment>
                <Segment>
                  <Header>Anexo</Header>
                  <Input
                    id="anexoImg"
                    fluid
                    className="input-image"
                    type="file"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                  <br />
                  <Image size="large" id="blah" src={previewImg} alt="Anexo" />
                </Segment>
              </div>
            ) : null}
            {step === "PAC" ? (
              <Segment>
                <Header>Acción Tomada</Header>
                <Form>
                  <TextArea
                    placeholder="Escriba la Acción Tomada aquí..."
                    value={plan}
                    onChange={(e) => {
                      setPlan(e.target.value);
                      console.log(e.target.value);
                    }}
                  ></TextArea>
                </Form>
                <Header>Fecha de Cierre Previsto</Header>
                <Input
                  fluid
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                  defaultValue={startDate}
                />
                <Header>Responsable del Plan</Header>
                <Dropdown
                  fluid
                  search
                  selection
                  placeholder="Seleccionar Responsable del Plan"
                  options={personal}
                  value={resp}
                  onChange={(e, { value }) => {
                    setResp(value);
                    console.log(value);
                  }}
                />
              </Segment>
            ) : null}
          </Segment>
        </div>
      ) : (
        <Button
          color="red"
          inverted
          fluid
          icon="key"
          content="Sign In"
          onClick={() => {
            iniciar();
          }}
        />
      )}
    </div>
  );
}

export default Registros;
