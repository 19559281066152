import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";
import "date-fns";
import "./App.css";
import {
  Container,
  Button,
  Input,
  Icon,
  Segment,
  Dropdown,
  Dimmer,
  Loader,
  Accordion,
  Label,
  Item,
  Card,
  Image,
  Header,
} from "semantic-ui-react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import "react-datepicker/dist/react-datepicker.css";
import userData from "./employee.json";
import { useParams } from "react-router";

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25568);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}

function Observacion({ user }) {
  let params = useParams();
  //Fechas
  let today = new Date();
  let startDate = new Date(today);
  startDate.setDate(startDate.getDate() - 30);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var hora = today.getHours();
  var minutos = today.getMinutes();
  dd < 10 ? (dd = "0" + dd) : (dd = today.getDate());
  mm < 10 ? (mm = "0" + mm) : (mm = today.getMonth() + 1);
  if (hora < 10) hora = "0" + hora;
  if (minutos < 10) minutos = "0" + minutos;
  let date = yyyy + "-" + mm + "-" + dd;
  //Fechas
  const [data, setData] = useState();
  const [myData, setMyData] = useState();
  const [dataFound, setDataFound] = useState();
  const [inicio, setInicio] = useState(startDate.toISOString().slice(0, 10));
  const [final, setFinal] = useState(date);
  const [activeIndex, setActiveIndex] = useState(0);
  const [buscar, setBuscar] = useState(false);
  const [creador, setCreador] = useState([]);
  const [activeItem, setActiveItem] = useState("");
  const dummyImg = "https://react.semantic-ui.com/images/wireframe/image.png";
  const [open, setOpen] = useState(false);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  var j;
  var lastr = userData.length;
  const personal = [];
  for (j = 0; j < lastr; j++) {
    if (userData[j].Nombre !== "") {
      personal.push({
        key: userData[j].Nombre,
        text: userData[j].Nombre,
        value: userData[j].Nombre,
      });
    }
  }

  async function sacardata() {
    var datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[0];
    const rows = await sheet.getRows();
    let lastr = rows.length + 1;
    var j;
    await sheet.loadCells("A1:V" + lastr.toString());
    for (j = 1; j < lastr; j++) {
      const ar = sheet.getCell(j, 0);
      const br = sheet.getCell(j, 1);
      const cr = sheet.getCell(j, 2);
      const dr = sheet.getCell(j, 3);
      const er = sheet.getCell(j, 4);
      const fr = sheet.getCell(j, 5);
      const gr = sheet.getCell(j, 6);
      const hr = sheet.getCell(j, 7);
      const ir = sheet.getCell(j, 8);
      const jr = sheet.getCell(j, 9);
      const kr = sheet.getCell(j, 10);
      const lr = sheet.getCell(j, 11);
      const mr = sheet.getCell(j, 12);
      const nr = sheet.getCell(j, 13);
      const or = sheet.getCell(j, 14);
      const pr = sheet.getCell(j, 15);
      const qr = sheet.getCell(j, 16);
      const rr = sheet.getCell(j, 17);
      const sr = sheet.getCell(j, 18);
      const tr = sheet.getCell(j, 19);
      const ur = sheet.getCell(j, 20);
      const vr = sheet.getCell(j, 21);
      datas.push({
        id: sr.value,
        fecha: ar.value,
        fecha_hora: br.value,
        nombre: cr.value,
        area: dr.value,
        rubro: er.value,
        categoria: fr.value,
        observaciones: gr.value,
        severidad: hr.value,
        pac: ir.value,
        fecha_cierre: jr.value,
        responsable: kr.value,
        anexo: lr.value,
        status: mr.value,
        confirmado: nr.value,
        extension: or.value,
        nueva_fecha: pr.value,
        detalle: qr.value,
        anexo2: rr.value,
        obsDetalle: tr.value,
        persObservadas: ur.value,
        aplica: vr.value,
      });
    }
    datas = datas.filter((item) => {
      return item.id === params.id;
    });

    if (datas.length > 1) {
      var personas = datas.map((e) => e.persObservadas).join(", ");
      console.log(personas);
      datas[0].persObservadas = personas;
      datas = datas.slice(0, 1);
    }

    setData(datas);
  }

  React.useEffect(() => {
    console.log(params);
    !data && sacardata();
    data && console.log(data);
  }, []);

  return (
    <Container>
      {data && (
        <Segment
          onClick={() => {
            console.log(data);
          }}
        >
          <Item>
            <Item.Image size="medium" src={data[0].anexo} />

            <Item.Content>
              <Item.Header as="h2">
                {data[0].rubro + " - " + data[0].categoria}
              </Item.Header>
              <Item.Description>
                <p>
                  <b>Detalle: </b> {data[0].observaciones}
                  {data[0].obsDetalle && " : " + data[0].obsDetalle}
                </p>
                <p>
                  <b>Fecha: </b>{" "}
                  {ExcelDateToJSDate(data[0].fecha).toISOString().slice(0, 10)}
                </p>
                <p>
                  <b>Area: </b> {data[0].area}
                </p>
                <p>
                  <b>Registrado por: </b> {data[0].nombre}
                </p>
                <p>
                  <b>Persona(s) Observada(s): </b> {data[0].persObservadas}
                </p>
              </Item.Description>
            </Item.Content>
          </Item>
        </Segment>
      )}
    </Container>
  );
}

export default Observacion;
