import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import { useHistory } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { storage } from "./firebase/firebase";
import { Contexto } from "./Contexto";
import { GoogleSpreadsheet } from "google-spreadsheet";
import imageCompression from "browser-image-compression";
import { Dropdown, Grid, Header, Input, Segment } from "semantic-ui-react";

function Minuta() {
  const { personal } = useContext(Contexto);
  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <Segment basic>
            <Header content="Administración de Minuta" />
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <b>Fecha*</b>
          <Input fluid type="date"></Input>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Segment basic>
            <b>Participantes</b>
            <Dropdown options={personal} fluid search selection />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment basic>
            <b>Reunion</b>
            <Dropdown options={personal} fluid search selection />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment basic>
            <b>Reunion</b>
            <Dropdown options={personal} fluid search selection />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default Minuta;
