import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import { useHistory } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import userData from "./employee.json";
import {
  Button,
  Dimmer,
  Loader,
  Header,
  Grid,
  Input,
  Form,
  Dropdown,
} from "semantic-ui-react";
import { Contexto } from "./Contexto";
import { GoogleSpreadsheet } from "google-spreadsheet";

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25568);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  var date_data = new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );

  return date_data;
}

function FechaStringShort(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${ye}-${mo < 10 ? "0" + mo : mo}`;
  return fechaStr;
}

function FechaStringLong(fecha) {
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  var hora = new Intl.DateTimeFormat("en-GB", options).format(fecha);
  //var fechaStr = `${ye}-${mo < 10 ? "0" + mo : mo}-${da} ${hora}`;
  var fechaStr = hora;
  return fechaStr;
}

function Schedule() {
  const [persona, setPersona] = useState(); //variable para responsable de recorrido
  const [lugar, setLugar] = useState(); //variable para area de recorrido
  const {
    ["user"]: [user, setUser],
  } = useContext(Contexto);
  const { ["personal"]: personal } = useContext(Contexto);
  const {
    ["areas"]: [areas, setAreas],
  } = useContext(Contexto);
  const [listaArea, setListaArea] = useState();
  const [saving, setSaving] = useState(false);
  const userInfo = userData.filter((item) => {
    return item.Nombre === user;
  });
  const history = useHistory();
  const [fecha, setFecha] = useState(FechaStringShort(new Date())); //variable para fecha de recorrido
  const sacarAreas = async () => {
    var data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[1705686078];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    const encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    setAreas(datas);
  };
  useEffect(() => {
    if (!areas) {
      sacarAreas();
    } else {
      var j;
      var lastr = areas.length;
      const area = [];
      for (j = 0; j < lastr; j++) {
        if (areas[j].name !== "") {
          area.push({
            key: areas[j].name,
            text: areas[j].name,
            value: areas[j].name,
          });
        }
      }
      setListaArea(area);
    }
  }, [areas]);

  async function registrar() {
    setSaving(true);
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[762667679];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    let lastr = rows.length;
    while (lastr--) {
      try {
        console.log(rows[lastr].Usuario);
        if (
          rows[lastr].Nombre === persona &&
          rows[lastr].Fecha === fecha &&
          rows[lastr].Area === lugar
        ) {
          console.log("Borrando");
          await rows[lastr].delete();
        }
      } catch (err) {
        console.log(err);
        break;
      }
    }
    await sheet.addRow({
      Nombre: persona,
      Fecha: "'" + fecha,
      Area: lugar,
      Usuario: user,
      FechaProgramado: FechaStringLong(new Date()),
    });
    alert("Datos Guardados");
    setSaving(false);
    setPersona();
    setLugar();
  }

  return (
    <Grid textAlign="center" style={{ height: "50vh" }} verticalAlign="middle">
      <Dimmer active={saving} page>
        <Loader size="massive">{saving ? "Saving" : "Loading"}</Loader>
      </Dimmer>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" style={{ color: "#F44336" }} textAlign="center">
          Programar Recorrido
        </Header>
        <Form>
          <b>Nombre*</b>
          <Dropdown
            fluid
            search
            selection
            clearable
            placeholder="Nombre de Empleado..."
            defaultValue={persona}
            options={personal}
            onChange={(e, { value }) => {
              setPersona(value);
            }}
          ></Dropdown>
          <b>Area*</b>
          <Dropdown
            fluid
            search
            selection
            clearable
            placeholder="Area..."
            defaultValue={lugar}
            options={listaArea}
            onChange={(e, { value }) => {
              setLugar(value);
            }}
          ></Dropdown>
          <b>Fecha*</b>
          <Input
            fluid
            type="month"
            size="tiny"
            defaultValue={fecha}
            onChange={(e, { value }) => {
              setFecha(value);
            }}
          ></Input>
          <br></br>
          <Button
            disabled={!persona || !lugar}
            negative
            fluid
            onClick={() => {
              registrar();
            }}
          >
            Programar
          </Button>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

export default Schedule;
