import React, { useState, useEffect, useContext } from "react";
import "date-fns";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { Contexto } from "./Contexto";
import { useHistory } from "react-router";
import userData from "./employee.json";
import { storage } from "./firebase/firebase";
import imageCompression from "browser-image-compression";

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

function FechaStringLong(fecha) {
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  var hora = new Intl.DateTimeFormat("en-GB", options).format(fecha);
  var fechaStr = hora;
  return fechaStr;
}

function FechaStringShort(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${ye}-${mo < 10 ? "0" + mo : mo}-${da}`;
  return fechaStr;
}

function ActoCondiciones({ user, acto }) {
  const [fecha, setFecha] = useState(FechaStringShort(new Date()));
  const [areas, setAreas] = useState();
  const [area, setArea] = useState();
  const [buscarPO, setBuscarPO] = useState();
  const [pObservadas, setPObservadas] = useState([]);
  const [observacion, setObservacion] = useState();
  const [accion, setAccion] = useState();
  const [anexo, setAnexo] = useState();
  const [previewImgs, setPreviewImgs] = useState([]);
  const [imageAsFile, setImageAsFile] = useState([]);
  const [imagenes, setImg] = useState([]);
  const [caption, setCaption] = useState("Cargando...");

  const sacardata = async () => {
    setCaption("Cargando...");
    var elem = document.getElementById("cargando");
    elem.style.width = "100%";
    elem.style.height = "100%";
    let data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets

    //AREAS
    let sheet = doc.sheetsById[1705686078];
    let rows = await sheet.getRows();
    let cols = await sheet.columnCount;
    let encabezado = await sheet.headerValues;
    let datas_areas = [];
    let lastr = rows.length + 1;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (var j = 1; j < lastr; j++) {
      data = [];
      for (var i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas_areas.push(data);
    }
    setAreas(datas_areas);
    var elem = document.getElementById("cargando");
    elem.style.width = "0%";
    elem.style.height = "0%";
  };

  async function registrar() {
    setCaption("Guardando...");
    var elem = document.getElementById("cargando");
    elem.style.width = "100%";
    elem.style.height = "100%";

    console.log(imagenes);
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[1336569393];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    let lastr = rows.length;
    try {
      await sheet.addRow({
        Area: area,
        Usuario: user,
        Fecha_Registro: FechaStringLong(new Date()),
        Fecha: fecha,
        Observacion: observacion,
        Accion: accion,
        Personas_Observadas: pObservadas.length > 0 ? pObservadas.join() : null,
        Anexos: imagenes.length > 0 ? imagenes.join() : null,
        Acto_Condicion: acto,
      });
    } catch (err) {
      console.log(err);
    }

    setArea();
    setFecha(FechaStringShort(new Date()));
    setPObservadas([]);
    setObservacion();
    setAccion();
    setAnexo();
    setPreviewImgs([]);
    setImageAsFile([]);
    setImg([]);
    var are = document.getElementById("area");
    are.value = "";
    var obs = document.getElementById("observacion");
    obs.value = "";
    var acc = document.getElementById("accion");
    console.log(acc);
    acc.value = "";
    var elem = document.getElementById("cargando");
    elem.style.width = "0%";
    elem.style.height = "0%";
  }

  async function handleFireBaseUpload(imagen) {
    return new Promise((resolve, _reject) => {
      const uploadTask = storage
        .ref(`/images/Actos/${imagen.name}`)
        .put(imagen);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref("images/Actos")
            .child(imagen.name)
            .getDownloadURL()
            .then(async (fireBaseUrl) => {
              imagenes.push(fireBaseUrl);
              resolve("OK");
            });
        }
      );
    });
  }

  useEffect(() => {
    if (!areas) {
      sacardata();
    } else {
    }
  }, []);

  function handleArea(nombre, tipo) {
    let elemValor = tipo === "area" ? "area" : "personas";
    let lista = document.getElementsByClassName("acto-lista-data");
    let valor = document.getElementsByName(elemValor)[0];
    if (tipo === "area") {
      setArea(nombre);
      valor.value = nombre;
      lista[0].style.display = "none";
    } else if (tipo === "personas") {
      if (!pObservadas.includes(nombre)) {
        setPObservadas((oldArray) => [...oldArray, nombre]);
      }
      lista[1].style.display = "none";
    }
  }

  async function handleImageUpload(event) {
    let archivos = event.target.files;
    console.log(archivos);
    if (archivos.length > 5 || archivos.length + previewImgs.length > 5) {
      alert("Solo las primeras 5 fotos seran cargadas");
    }
    var reader = new FileReader();
    for (let archivo of archivos) {
      reader.onload = function (e) {
        let imagen = e.target.result;
        setPreviewImgs((prev) => [...prev, imagen]);
      };
      reader.readAsDataURL(archivo);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(archivo, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB
        setImageAsFile((prev) => [...prev, compressedFile]);
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div
      className="acto"
      onClick={() => {
        console.log(area, pObservadas, anexo, imageAsFile, previewImgs);
      }}
    >
      <div id="cargando" className="cargando">
        <div className="loaderH"></div>

        {caption}
      </div>
      <div className="acto-item">
        <div style={{ display: "flex" }}>
          <h1>{acto === "acto" ? "ACTO INSEGURO" : "CONDICION INSEGURA"}</h1>
        </div>
      </div>

      <div className="acto-item">
        Fecha*
        <div name="fecha">
          <input
            type="date"
            defaultValue={fecha}
            min={"2022-01-01"}
            max={FechaStringShort(new Date())}
            onChange={(e) => {
              setFecha(e.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className="acto-item">
        Area*
        <div className="acto-lista">
          <input
            id="area"
            name="area"
            className="acto-lista-search"
            type="search"
            placeholder="Seleccionar Area..."
            onFocus={() => {
              let elem = document.getElementsByClassName("acto-lista-data")[0];
              elem.style.display = "block";
            }}
            onBlur={() => {
              let elem = document.getElementsByClassName("acto-lista-data")[0];
            }}
            onChange={(e) => {
              setArea(e.target.value);
            }}
          ></input>
          <div className="acto-lista-data">
            {areas &&
              areas.map((x) => {
                if (
                  area
                    ? x.name.toLowerCase().includes(area.toLowerCase())
                    : true
                ) {
                  return (
                    <div className="acto-lista-item">
                      <ol
                        onClick={() => {
                          handleArea(x.name, "area");
                        }}
                      >
                        {x.name}
                      </ol>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
      <div className="acto-item">
        Observación*
        <div name="observacion">
          <textarea
            id="observacion"
            placeholder="Detalle..."
            onChange={(e) => {
              setObservacion(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
      <div className="acto-item">
        Anexos
        <div name="anexo">
          <label className="custom-file-upload">
            <input
              className="custom-file-input"
              type="file"
              multiple="multiple"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => {
                handleImageUpload(e);
              }}
            ></input>
            <i className="material-icons">add_a_photo</i>
          </label>
          <div style={{ margin: "5px", position: "relative" }}>
            {previewImgs.length > 0 &&
              previewImgs.map((x, idx) => {
                return (
                  <div style={{ position: "relative" }}>
                    <img src={x} alt="" width="240px" className="imgPrev"></img>
                    <i
                      className="material-icons eliminar"
                      onClick={() => {
                        setImageAsFile((prev) =>
                          prev.filter((n, i) => i !== idx)
                        );
                        setPreviewImgs((prev) =>
                          prev.filter((n, i) => i !== idx)
                        );
                      }}
                    >
                      clear
                    </i>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="acto-item">
        Personas Observadas {acto === "acto" && "*"}
        <div className="acto-lista">
          {pObservadas.length != 0 && (
            <div className="listaPersonas">
              {pObservadas.map((x) => {
                return (
                  <div style={{ height: "30px", position: "relative" }}>
                    {x}
                    <i
                      className="material-icons eliminar"
                      onClick={() => {
                        setPObservadas((prev) => prev.filter((n) => n !== x));
                      }}
                    >
                      {" "}
                      clear
                    </i>
                  </div>
                );
              })}
            </div>
          )}
          <input
            className="acto-lista-search"
            type="search"
            placeholder="Seleccionar Personas Observadas..."
            onFocus={() => {
              let elem = document.getElementsByClassName("acto-lista-data")[1];
              elem.style.display = "block";
            }}
            onBlur={() => {
              let elem = document.getElementsByClassName("acto-lista-data")[1];
            }}
            onChange={(e) => {
              setBuscarPO(e.target.value);
            }}
          ></input>
          <div className="acto-lista-data">
            {userData &&
              userData.map((x) => {
                if (
                  buscarPO
                    ? x.Nombre.toLowerCase().includes(buscarPO.toLowerCase())
                    : true
                ) {
                  return (
                    <div className="acto-lista-item">
                      <ol
                        onClick={() => {
                          handleArea(x.Nombre, "personas");
                        }}
                      >
                        {x.Nombre}
                      </ol>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
      <div className="acto-item">
        Acción Tomada*
        <div name="accion">
          <textarea
            id="accion"
            placeholder="Detalle..."
            onChange={(e) => {
              setAccion(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
      {
        <div className="acto-item">
          <button
            className="acto-item-button"
            disabled={
              !area ||
              !observacion ||
              !accion ||
              (acto === "acto" && pObservadas.length === 0)
            }
            onClick={async () => {
              setCaption("Subiendo Imagenes...");
              var elem = document.getElementById("cargando");
              elem.style.width = "100%";
              elem.style.height = "100%";
              if (imageAsFile.length > 0) {
                for (var imagen of imageAsFile) {
                  await handleFireBaseUpload(imagen);
                }
              }
              registrar();
            }}
          >
            Guardar
          </button>
        </div>
      }
    </div>
  );
}

export default ActoCondiciones;
