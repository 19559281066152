import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyD2qP-hydl9iaoxa0FO07FG5KDu9d3bGuA",
  authDomain: "sasso-test-285920.firebaseapp.com",
  databaseURL: "https://sasso-test-285920.firebaseio.com",
  projectId: "sasso-test-285920",
  storageBucket: "sasso-test-285920.appspot.com",
  messagingSenderId: "191632707331",
  appId: "1:191632707331:web:954ff5c5c38d8dcae4e336",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const auth = firebase.auth();
const firestore = firebase.firestore();
const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};
const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { Nombre, Correo, Apellido } = user;
    try {
      await userRef.set({
        Nombre,
        Correo,
        Apellido,
        ...additionalData,
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};
export {
  storage,
  auth,
  firestore,
  generateUserDocument,
  getUserDocument,
  firebase as default,
};
