import React, { useState, useContext } from "react";
import "./App.css";
import "./App_extra.css";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Home from "./Home.js";
import "semantic-ui-css/semantic.min.css";
import userData from "./employee.json";
import Add from "./images/fondo.jpg";
import {
  Menu,
  Icon,
  Button,
  Segment,
  Sidebar,
  Dropdown,
} from "semantic-ui-react";
import Observacion from "./observacion";
import Tareas from "./tareas";
import Consultas from "./consulta";
import Registrar from "./registrar";
import Login from "./Login";
import { Contexto } from "./Contexto";
import Perfil from "./profile";
import Schedule from "./schedule";
import ReporteDeTurno from "./reporteDeTurno";
import Minuta from "./minuta";
import Desviaciones from "./desviaciones";
import ReportHome from "./reportes_home";
import ActoCondiciones from "./aci";

function App() {
  let iniciado = null;
  let caption = "Sign In";
  if (sessionStorage.getItem("user")) {
    iniciado = sessionStorage.user;
    caption = "Sign Out";
  } else if (localStorage.getItem("user")) {
    iniciado = localStorage.user;
    caption = "Sign Out";
  }
  const {
    ["user"]: [user, setUser],
  } = useContext(Contexto);
  const {
    ["pagina"]: [pagina, setPagina],
  } = useContext(Contexto);
  const {
    ["width"]: [width, setWidth],
  } = useContext(Contexto);
  const breakpoint = 620;
  const {
    ["areas"]: [areas, setAreas],
  } = useContext(Contexto);
  const {
    ["tipoReporte"]: [tipoReporte, setTipoReporte],
  } = useContext(Contexto);
  const [session, setSession] = useState(caption);
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const [recordar, setRecordar] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };
  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userPassword") {
      setPassword(value);
    }
  };

  const login = (event, password) => {
    event.preventDefault();
    var found = false;
    for (var i = 0; i < userData.length; i++) {
      if (userData[i].id === password) {
        found = true;
        setUser(userData[i].Nombre);
        sessionStorage.user = userData[i].Nombre;
        if (recordar) {
          localStorage.user = userData[i].Nombre;
        }
        setSession("Sign Out");
        break;
      }
    }
    if (!found) alert(`Usuario No encontrado`);
  };

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Router basename={"/"}>
      {pagina == "/Reporte" && (
        <Link
          to={
            tipoReporte == "Reporte de Turno" ? "/reporte_turno" : "/recorrido"
          }
          onClick={() => {
            setPagina(
              tipoReporte == "Reporte de Turno"
                ? "/reporte_turno"
                : "/recorrido"
            );
          }}
        >
          <i class="material-icons nuevo-reporte">add_circle_outline</i>
        </Link>
      )}
      {pagina == "/Reporte" && (
        <span className="nuevo-reporte-caption">Nuevo Reporte</span>
      )}
      <div style={{ padding: "2%", backgroundImage: `url(${Add})` }}>
        <Menu color="red" size="huge">
          <Menu.Item
            header
            as={Link}
            to="/home"
            onClick={() => {
              setPagina("/");
            }}
          >
            <b style={{ color: "red" }}>Mi Cultura QSEB</b>
          </Menu.Item>
          {width < breakpoint ? (
            <Menu.Item position="right">
              <Icon
                size="large"
                name="bars"
                color="red"
                onClick={() => {
                  setVisible(true);
                }}
              />
            </Menu.Item>
          ) : (
            <>
              <Dropdown item text="Reportes">
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Reporte de Turno"
                    as={Link}
                    to={user ? "/Reporte" : "/Login"}
                    onClick={() => {
                      setTipoReporte("Reporte de Turno");
                      setPagina("/Reporte");
                      setAreas();
                    }}
                  />
                  <Dropdown.Item
                    text="Recorridos"
                    as={Link}
                    to={user ? "/Reporte" : "/Login"}
                    onClick={() => {
                      setTipoReporte("Reporte de Recorrido");
                      setPagina("/Reporte");
                      setAreas();
                    }}
                  />
                  <Dropdown.Item
                    text="Programar Recorridos"
                    as={Link}
                    to={user ? "/programar" : "/Login"}
                    onClick={() => {
                      setPagina("/programar");
                    }}
                  />
                  <Dropdown.Item
                    text="Actos Inseguros"
                    as={Link}
                    to={user ? "/acto_inseguro" : "/Login"}
                    onClick={() => {
                      setPagina("/acto_inseguro");
                    }}
                  />
                  <Dropdown.Item
                    text="Condiciones Inseguras"
                    as={Link}
                    to={user ? "/condicion_insegura" : "/Login"}
                    onClick={() => {
                      setVisible(false);
                      setPagina("/condicion_insegura");
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item name="Noticias">Noticias</Menu.Item>
              <Menu.Item position="right">
                {user ? (
                  //<Icon
                  //  size="large"
                  // name="user circle"
                  //  color={user ? "green" : "black"}
                  //>
                  <Button
                    icon="user "
                    color="green"
                    circular
                    as={Link}
                    to="/perfil"
                  />
                ) : (
                  <Button
                    content="Iniciar Sesion"
                    style={{ backgroundColor: "#F44336", color: "white" }}
                    as="a"
                    href="/Login"
                  />
                )}
              </Menu.Item>
            </>
          )}
        </Menu>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            onHide={() => setVisible(false)}
            vertical
            direction="right"
            visible={visible}
            width="wide"
            color="red"
          >
            <Menu.Item
              name="Reporte"
              as={Link}
              to={user ? "/Reporte" : "/Login"}
              onClick={() => {
                setTipoReporte("Reporte de Turno");
                setVisible(false);
                setPagina("/Reporte");
                setAreas();
              }}
            >
              Reporte de Turno
            </Menu.Item>
            <Menu.Item
              name="Recorridos"
              as={Link}
              to={user ? "/Reporte" : "/Login"}
              onClick={() => {
                setTipoReporte("Reporte de Recorrido");
                setVisible(false);
                setPagina("/Reporte");
                setAreas();
              }}
            >
              Recorridos
            </Menu.Item>
            <Menu.Item
              name="ProgramarRecorridos"
              as={Link}
              to={user ? "/programar" : "/Login"}
              onClick={() => {
                setVisible(false);
                setPagina("/programar");
              }}
            >
              Programar Recorridos
            </Menu.Item>
            <Menu.Item
              name="ActosI"
              as={Link}
              to={user ? "/acto_inseguro" : "/Login"}
              onClick={() => {
                setVisible(false);
                setPagina("/acto_inseguro");
              }}
            >
              Actos Inseguros
            </Menu.Item>
            <Menu.Item
              name="CondicionesI"
              as={Link}
              to={user ? "/condicion_insegura" : "/Login"}
              onClick={() => {
                setVisible(false);
                setPagina("/condicion_insegura");
              }}
            >
              Condiciones Inseguras
            </Menu.Item>
            <Menu.Item name="Noticias">Noticias</Menu.Item>
            {user && user.includes("Cheung") && (
              <Menu.Item
                name="Minutas"
                as={Link}
                to={user ? "/minutas" : "/Login"}
                onClick={() => {
                  setVisible(false);
                  setPagina("/minutas");
                }}
              >
                Minutas
              </Menu.Item>
            )}
            <Menu.Item
              name="Perfil"
              as={Link}
              to={user ? "/perfil" : "/Login"}
              onClick={() => {
                setPagina(user ? "/perfil" : "/Login");
                setVisible(false);
              }}
            >
              {user ? (
                user
              ) : (
                <Button
                  content="Iniciar Sesion"
                  style={{ backgroundColor: "#F44336", color: "white" }}
                  as={Link}
                  onClick={() => {
                    setPagina(user ? "/perfil" : "/Login");
                    setVisible(false);
                  }}
                  to="/Login"
                />
              )}
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher>
            <Segment
              style={{
                backgroundColor: "whitesmoke",
                height: "90vh",
                overflowY: "auto",
              }}
            >
              <Switch>
                <Route
                  exact
                  path="/home"
                  render={(props) =>
                    user ? (
                      <Home {...props} user={user} />
                    ) : (
                      <Login {...props} user={user} />
                    )
                  }
                />
                <Route
                  path="/registros"
                  render={(props) => <Registrar {...props} user={user} />}
                />
                <Route
                  path="/tareas"
                  render={(props) => <Tareas {...props} user={user} />}
                />
                <Route
                  path="/observaciones"
                  render={(props) => <Consultas {...props} user={user} />}
                />
                <Route
                  path="/observacion/:id"
                  render={(props) => <Observacion {...props} user={user} />}
                />
                <Route
                  path="/Login"
                  render={(props) => <Login {...props} user={user} />}
                />

                <Route
                  path="/reporte"
                  render={(props) => <ReportHome {...props} user={user} />}
                />
                <Route
                  path="/reporte_turno"
                  render={(props) => <ReporteDeTurno {...props} user={user} />}
                />
                <Route
                  path="/recorrido"
                  render={(props) => <ReporteDeTurno {...props} user={user} />}
                />
                <Route
                  path="/acto_inseguro"
                  render={(props) => (
                    <ActoCondiciones {...props} user={user} acto={"acto"} />
                  )}
                />
                <Route
                  path="/condicion_insegura"
                  render={(props) => (
                    <ActoCondiciones {...props} user={user} acto={"cond"} />
                  )}
                />
                <Route
                  path="/perfil"
                  render={(props) => <Perfil {...props} user={user} />}
                />
                <Route
                  path="/desviaciones"
                  render={(props) => <Desviaciones {...props} user={user} />}
                />
                <Route
                  path="/minutas"
                  render={(props) => <Minuta {...props} user={user} />}
                />
                <Route
                  path="/programar"
                  render={(props) => <Schedule {...props} user={user} />}
                />
                <Route
                  path="/"
                  //render={(props) => <Home {...props} user={user} />}
                  render={(props) =>
                    user ? (
                      <Home {...props} user={user} />
                    ) : (
                      <Login {...props} user={user} />
                    )
                  }
                />
              </Switch>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    </Router>
  );
}

export default App;
