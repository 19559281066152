import React, { useState, useEffect, useContext } from "react";

import "semantic-ui-css/semantic.min.css";
import "date-fns";
import "./App.css";
import useFitText from "use-fit-text";
import {
  Dimmer,
  Loader,
  Grid,
  Header,
  Button,
  Icon,
  Table,
  Segment,
  List,
} from "semantic-ui-react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { Contexto } from "./Contexto";
import { useHistory } from "react-router";

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25568);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  var date_data = new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );

  return date_data;
}

function FechaStringShort(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${ye}-${mo < 10 ? "0" + mo : mo}-${da}`;
  return fechaStr;
}

function FechaStringLong(fecha) {
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  var hora = new Intl.DateTimeFormat("en-GB", options).format(fecha);
  var fechaStr = hora;
  return fechaStr;
}

function Home({ user }) {
  const history = useHistory();
  const [data, setData] = useState();
  const [qsebSummary, setQSEBsummary] = useState();
  const [qsebCat, setQSEBcat] = useState();
  const [qsebSubcat, setQSEBsubcat] = useState();

  const [filtros, setFiltros] = useState(false);

  const { color: color } = useContext(Contexto);
  const { breakpoint: breakpoint } = useContext(Contexto);
  const { userData: userData } = useContext(Contexto);
  const {
    ["grupoArea"]: [grupoArea, setGrupoArea],
  } = useContext(Contexto);
  const estados = [
    { name: "saving", caption: "Guardando" },
    { name: "loading", caption: "Cargando" },
    { name: "deleting", caption: "Borrando" },
  ];
  const [status, setStatus] = useState();
  function handleSemana(fecha) {
    var anno = fecha.substring(0, 4);
    var mes = fecha.substring(5, 7);
    var dia = fecha.substring(8, 10);
    var d = new Date(anno, mes - 1, dia);
    let weekday = d.getDay();
    weekday === 0 ? (weekday = 7) : (weekday = weekday);
    d.setDate(d.getDate() - weekday + 1);
    return FechaStringShort(d);
  }
  function inicioDeSemana() {
    let inicioDeSemana = new Date();
    return inicioDeSemana;
  }
  function lastWeek() {
    let today = new Date();
    today.setDate(today.getDate() - 7);
    return today;
  }
  function diaDeSemana(fecha) {
    let wd = fecha.getDay();
    wd === 0 ? (wd = 7) : (wd = wd);
    return wd;
  }

  function getMonths() {
    let today = new Date();
    let esteMes = today.getMonth();
    let currentYear = today.getFullYear();
    let lastMonthYear = esteMes == 12 ? currentYear - 1 : currentYear;
    let currentMonth =
      currentYear.toString() +
      (esteMes + 1 < 10 ? "-0" : "-") +
      (esteMes + 1).toString();
    let lastMonth =
      lastMonthYear.toString() +
      (esteMes < 10 ? "-0" : "-") +
      esteMes.toString();
    return { currentMonth, lastMonth };
  }

  //Fechas
  const [dataSemana, setDS] = useState();
  const [semana, setSemana] = useState(
    FechaStringShort(
      inicioDeSemana().setDate(
        inicioDeSemana().getDate() - diaDeSemana(inicioDeSemana()) + 1
      )
    )
  );
  const [semanaPasada, setSemanaPasada] = useState(
    FechaStringShort(
      lastWeek().setDate(lastWeek().getDate() - diaDeSemana(lastWeek()) + 1)
    )
  );
  const [mes, setMes] = useState(getMonths().currentMonth);
  const [mesRecorrido, setMesRecorrido] = useState(getMonths().currentMonth);

  const tiposDeReportes = ["Reporte de Turno", "Reporte de Recorrido"];
  const [areas, setAreas] = useState();
  const turnos = ["Todos", "Turno 1", "Turno 2", "Turno 3"];
  const periodos = ["Esta Semana", "Semana Pasada", "Este Mes", "Mes"];
  const [tipoReporte, setTipoReporte] = useState("Reporte de Turno");
  const [turno, setTurno] = useState("Todos");
  const [area, setArea] = useState("Todas");
  const [periodo, setPeriodo] = useState("Esta Semana");

  const [schedule, setSchedule] = useState();

  const selected = <div className="selected"></div>;

  const sacardata = async () => {
    var elem = document.getElementById("cargando");
    elem.style.width = "100%";
    elem.style.height = "100%";
    let data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    let sheet = doc.sheetsById[1249329599];
    let rows = await sheet.getRows();
    let cols = await sheet.columnCount;
    let encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    //AREAS
    sheet = doc.sheetsById[1705686078];
    rows = await sheet.getRows();
    cols = await sheet.columnCount;
    encabezado = await sheet.headerValues;
    let datas_areas = [];
    lastr = rows.length + 1;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas_areas.push(data);
    }
    setAreas(datas_areas);

    const unique = [...new Set(datas_areas.map((item) => item.grupo))];
    unique.sort();
    unique.unshift("Todas");
    setGrupoArea(unique);
    //AREAS
    //Programacion
    sheet = doc.sheetsById[762667679];
    rows = await sheet.getRows();
    cols = await sheet.columnCount;
    encabezado = await sheet.headerValues;
    let programacion = [];
    lastr = rows.length + 1;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      programacion.push(data);
    }
    //Programacion
    datas.forEach((x) => {
      if (x.Incumple === "Y") {
        x.cumple = 0;
      } else {
        x.cumple = 1;
      }
      x.Mes = FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido)).substring(
        0,
        7
      );
      x.total = 1;
      let grupito = datas_areas.filter((lugar) => lugar.name === x.Area);
      x.grupo = grupito[0].grupo;
    });

    programacion.forEach((prog) => {
      if (
        datas.filter(
          (x) =>
            x.Reporte.includes("Recorrido") &&
            x.Usuario === prog.Nombre &&
            x.Area === prog.Area &&
            x.Mes === prog.Fecha
        ).length > 0
      ) {
        prog.realizado = 1;
      } else {
        prog.realizado = 0;
      }
      prog.programado = 1;
    });
    programacion = programacion.map(
      ({ Nombre, Fecha, realizado, programado }) => ({
        Nombre,
        Fecha,
        realizado,
        programado,
      })
    );
    programacion = [
      ...programacion
        .reduce((r, o) => {
          const key = o.Nombre + o.Fecha;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              realizado: 0,
              programado: 0,
            });

          item.realizado += o.realizado;
          item.programado += o.programado;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    programacion.forEach((prog) => {
      var cumplimiento = isNaN(prog.realizado / prog.programado)
        ? "-"
        : Math.round((prog.realizado / prog.programado) * 100 * 10) / 10 + "%";
      prog.cumplimiento = cumplimiento;
    });
    setSchedule(programacion);

    let { currentMonth, lastMonth } = getMonths();
    let dataFiltrado = datas.filter(
      (item) =>
        item.Reporte === tipoReporte &&
        handleSemana(
          FechaStringShort(ExcelDateToJSDate(item.FechaRecorrido))
        ) == semana
    );
    if (dataFiltrado.length == 0) {
      setPeriodo("Este Mes");
      dataFiltrado = datas.filter(
        (item) =>
          item.Reporte === tipoReporte &&
          FechaStringShort(ExcelDateToJSDate(item.FechaRecorrido)).substring(
            0,
            7
          ) == currentMonth
      );
    }

    if (dataFiltrado.length == 0) {
      setPeriodo("Este Mes");
      dataFiltrado = datas.filter(
        (item) =>
          item.Reporte === tipoReporte &&
          FechaStringShort(ExcelDateToJSDate(item.FechaRecorrido)).substring(
            0,
            7
          ) == lastMonth
      );
    }
    let summary = dataFiltrado.map(({ Tipo, cumple, total }) => ({
      Tipo,
      cumple,
      total,
    }));
    summary = [
      ...summary
        .reduce((r, o) => {
          const key = o.Tipo;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              cumple: 0,
              total: 0,
            });

          item.cumple += o.cumple;
          item.total += o.total;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    summary.forEach((x) => {
      var cumplimiento = isNaN(x.cumple / x.total)
        ? "-"
        : Math.round((x.cumple / x.total) * 100 * 10) / 10 + "%";
      x.cumplimiento = cumplimiento;
    });
    setQSEBsummary(summary);
    let categories = dataFiltrado.map(({ Tipo, Categoria, cumple, total }) => ({
      Tipo,
      Categoria,
      cumple,
      total,
    }));
    categories = [
      ...categories
        .reduce((r, o) => {
          const key = o.Tipo + o.Categoria;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              cumple: 0,
              total: 0,
            });

          item.cumple += o.cumple;
          item.total += o.total;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    categories.forEach((x) => {
      var cumplimiento = isNaN(x.cumple / x.total)
        ? "-"
        : Math.round((x.cumple / x.total) * 100 * 10) / 10 + "%";
      x.cumplimiento = cumplimiento;
    });
    setQSEBcat(categories);
    let subcategories = dataFiltrado.map(
      ({ Tipo, Categoria, SubCategoria, cumple, total }) => ({
        Tipo,
        Categoria,
        SubCategoria,
        cumple,
        total,
      })
    );
    subcategories = [
      ...subcategories
        .reduce((r, o) => {
          const key = o.Tipo + o.Categoria + o.SubCategoria;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              cumple: 0,
              total: 0,
            });

          item.cumple += o.cumple;
          item.total += o.total;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    subcategories.forEach((x) => {
      var cumplimiento = isNaN(x.cumple / x.total)
        ? "-"
        : Math.round((x.cumple / x.total) * 100 * 10) / 10 + "%";
      x.cumplimiento = cumplimiento;
    });
    setQSEBsubcat(subcategories);
    setData(datas);
    var elem = document.getElementById("cargando");
    elem.style.width = "0%";
    elem.style.height = "0%";
  };

  function Filtrar() {
    const dataFiltrado = data.filter(
      (next) =>
        next.Reporte === tipoReporte &&
        (periodo === "Esta Semana"
          ? handleSemana(
              FechaStringShort(ExcelDateToJSDate(next.FechaRecorrido))
            ) == semana
          : periodo === "Semana Pasada"
          ? handleSemana(
              FechaStringShort(ExcelDateToJSDate(next.FechaRecorrido))
            ) == semanaPasada
          : periodo === "Este Mes"
          ? FechaStringShort(ExcelDateToJSDate(next.FechaRecorrido)).substring(
              0,
              7
            ) === getMonths().currentMonth
          : FechaStringShort(ExcelDateToJSDate(next.FechaRecorrido)).substring(
              0,
              7
            ) === mes) &&
        (tipoReporte.includes("Turno") && turno !== "Todos"
          ? next.Turno === parseInt(turno.slice(-1))
          : true) &&
        (area !== "Todas" ? next.grupo === area : true)
    );
    let summary = dataFiltrado.map(({ Tipo, cumple, total }) => ({
      Tipo,
      cumple,
      total,
    }));
    summary = [
      ...summary
        .reduce((r, o) => {
          const key = o.Tipo;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              cumple: 0,
              total: 0,
            });

          item.cumple += o.cumple;
          item.total += o.total;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    summary.forEach((x) => {
      var cumplimiento = isNaN(x.cumple / x.total)
        ? "-"
        : Math.round((x.cumple / x.total) * 100 * 10) / 10 + "%";

      x.cumplimiento = cumplimiento;
    });
    setQSEBsummary(summary);
    let categories = dataFiltrado.map(({ Tipo, Categoria, cumple, total }) => ({
      Tipo,
      Categoria,
      cumple,
      total,
    }));
    categories = [
      ...categories
        .reduce((r, o) => {
          const key = o.Tipo + o.Categoria;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              cumple: 0,
              total: 0,
            });

          item.cumple += o.cumple;
          item.total += o.total;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    categories.forEach((x) => {
      var cumplimiento = isNaN(x.cumple / x.total)
        ? "-"
        : Math.round((x.cumple / x.total) * 100 * 10) / 10 + "%";
      x.cumplimiento = cumplimiento;
    });
    setQSEBcat(categories);
    let subcategories = dataFiltrado.map(
      ({ Tipo, Categoria, SubCategoria, cumple, total }) => ({
        Tipo,
        Categoria,
        SubCategoria,
        cumple,
        total,
      })
    );
    subcategories = [
      ...subcategories
        .reduce((r, o) => {
          const key = o.Tipo + o.Categoria + o.SubCategoria;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              cumple: 0,
              total: 0,
            });

          item.cumple += o.cumple;
          item.total += o.total;
          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    subcategories.forEach((x) => {
      var cumplimiento = isNaN(x.cumple / x.total)
        ? "-"
        : Math.round((x.cumple / x.total) * 100 * 10) / 10 + "%";
      x.cumplimiento = cumplimiento;
    });
    setQSEBsubcat(subcategories);
  }

  useEffect(() => {
    if (!data) {
      sacardata();
    } else {
    }
  }, []);

  return (
    <div
      style={{ maxWidth: "1280px", margin: "auto", position: "relative" }}
      onClick={() => {
        console.log(semana);
      }}
    >
      <div id="cargando" className="cargando">
        <div className="loaderH"></div>
        Cargando...
      </div>
      <div
        id="mes"
        style={{
          position: "absolute",
          width: "50%",
          height: "50%",
          left: "25%",
          top: "50%",
          backgroundColor: "white",
          alignItems: "center",
          textAlign: "center",
          verticalAlign: "middle",
          zIndex: "1",
          padding: "2%",
          display: "none",
        }}
      >
        <p>Seleccionar el Mes...</p>
        <input
          type="month"
          value={mes}
          onChange={(e) => {
            setMes(e.target.value);
            setPeriodo(e.target.value);
          }}
        ></input>
        <button
          style={{
            position: "absolute",
            right: "5%",
            bottom: "5%",
          }}
          onClick={() => {
            let elem = document.getElementById("mes");
            elem.style.display = "none";
          }}
        >
          Confirmar
        </button>
      </div>
      <div id="filtros">
        <h1 style={{ gridColumn: "1 / 3" }}>
          Filtros
          <button
            style={{ float: "right", fontSize: "small", borderRadius: "10px" }}
            onClick={() => {
              Filtrar();
            }}
          >
            Aplicar
          </button>
        </h1>
        <select
          id="filtroTipo"
          onChange={(e) => {
            setTipoReporte(e.target.value);
          }}
        >
          {tiposDeReportes.map((tipo) => {
            return (
              <option id={tipo} value={tipo}>
                {tipo}
              </option>
            );
          })}
        </select>
        <select
          id="filtroPeriodo"
          onClick={(e) => {
            if (e.target.value === "Mes") {
              let elem = document.getElementById("mes");
              elem.style.display = "block";
            }
          }}
          onChange={(e) => {
            if (e.target.value !== "Mes") {
              setPeriodo(e.target.value);
              let elem = document.getElementById("mes");
              elem.style.display = "none";
            } else {
              let elem = document.getElementById("mes");
              elem.style.display = "block";
            }
          }}
        >
          {periodos.map((tipo) => {
            return <option value={tipo}>{tipo}</option>;
          })}

          <input
            type="month"
            value={mes}
            onChange={(e) => {
              setMes(e.target.value);
              setPeriodo(e.target.value);
            }}
          ></input>
        </select>
        <select
          id="filtroArea"
          onChange={(e) => {
            setArea(e.target.value);
          }}
        >
          {grupoArea &&
            grupoArea.map((tipo) => {
              return <option value={tipo}>{tipo}</option>;
            })}
        </select>
        {tipoReporte === "Reporte de Turno" && (
          <select
            id="filtroTurno"
            onChange={(e) => {
              setTurno(e.target.value);
            }}
          >
            {turnos.map((tipo) => {
              return <option value={tipo}>{tipo}</option>;
            })}
          </select>
        )}
      </div>
      <div id="resultados">
        <div id="qseb">
          {qsebSummary &&
            qsebSummary.map((item) => {
              let dashArr = `${(item.cumple / item.total) * 314},314`;
              return (
                <div id="contenedor" key={`${item.Tipo}-result`}>
                  <svg id="metro">
                    <circle
                      id="low"
                      r="50"
                      cx="50%"
                      cy="50%"
                      stroke="rgb(119, 119, 119,0.5)"
                      stroke-width="10"
                      fill="none"
                    ></circle>
                    <circle
                      id="low"
                      r="50"
                      cx="50%"
                      cy="50%"
                      stroke={
                        color.filter((x) => x.categoria === item.Tipo)[0].activo
                      }
                      stroke-width="10"
                      stroke-dasharray={dashArr}
                      fill="none"
                      style={{ transition: "width 2s" }}
                    ></circle>
                  </svg>
                  <div id="subtitulo">
                    <b>{item.Tipo}</b> <br></br> {item.cumplimiento}
                  </div>
                </div>
              );
            })}
        </div>
        <div id="detalles">
          {qsebSummary &&
            qsebSummary.map((x, idx) => {
              return (
                <div
                  key={"homeDetail-" + x.Tipo}
                  id={"homeDetail-" + x.Tipo}
                  style={{
                    backgroundImage: color.filter(
                      (item) => item.categoria === x.Tipo
                    )[0].gradient,
                    color: color.filter((item) => item.categoria === x.Tipo)[0]
                      .texto,
                  }}
                >
                  {qsebCat &&
                    qsebCat.map((y, idy) => {
                      if (y.Tipo === x.Tipo && y.cumplimiento !== "100%")
                        return (
                          <div
                            key={"homeDetailCat-" + idy}
                            id={"homeDetailCat-" + idy}
                            style={{ padding: "10px" }}
                          >
                            <div>
                              {y.Categoria} - {y.cumplimiento}
                            </div>
                            {qsebSubcat &&
                              qsebSubcat.map((z, idz) => {
                                if (
                                  z.Tipo === x.Tipo &&
                                  z.Categoria === y.Categoria &&
                                  z.cumplimiento !== "100%"
                                )
                                  return (
                                    <div style={{ marginLeft: "10px" }}>
                                      {z.SubCategoria} - {z.cumplimiento}
                                    </div>
                                  );
                              })}
                          </div>
                        );
                    })}
                </div>
              );
            })}
        </div>
      </div>
      <div id="progresos">
        <div id="recorridos">
          <h2>Recorridos</h2>
          <input
            style={{ margin: "10px 0px 10px 0px" }}
            type="Month"
            value={mesRecorrido}
            onChange={(e) => {
              setMesRecorrido(e.target.value);
            }}
          ></input>
          <div id="rectProgreso">
            {schedule &&
              schedule
                .filter((x) => x.Fecha === mesRecorrido)
                .map((x) => {
                  return (
                    <div>
                      <div>{x.Nombre}</div>
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left:
                              x.cumplimiento !== "100%"
                                ? `${x.cumplimiento}`
                                : "92%",
                          }}
                        >
                          <b>{x.cumplimiento}</b>
                        </div>
                        <svg style={{ width: "100%", height: "20px" }}>
                          <rect
                            id="rectlow"
                            width="100%"
                            height="20px"
                            fill="rgb(119, 119, 119,0.5)"
                          ></rect>
                          <rect
                            id="rectprog"
                            width={`${x.cumplimiento}`}
                            height="20px"
                            fill="rgb(4, 170, 109)"
                          ></rect>
                        </svg>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        <div id="otros">
          <div id="actos">
            <div>
              <h4>Actos</h4>
              <div id="cuadrado">10</div>
            </div>
            <div>
              <h4>Condiciones</h4>
              <div id="cuadrado">10</div>
            </div>
          </div>
          <div id="planes">
            <h2>Planes</h2>
            <div>
              <h4>Cerrados</h4>
              <div style={{ position: "relative" }}>
                <svg style={{ width: "100%", height: "20px" }}>
                  <rect
                    id="rectlow"
                    width="100%"
                    height="20px"
                    fill="rgb(119, 119, 119,0.5)"
                  ></rect>
                  <rect
                    id="rectprog"
                    width="0%"
                    height="20px"
                    fill="rgb(4, 170, 109)"
                  ></rect>
                </svg>
              </div>
            </div>
            <div>
              <h4>En Proceso</h4>
              <div style={{ position: "relative" }}>
                <svg style={{ width: "100%", height: "20px" }}>
                  <rect
                    id="rectlow"
                    width="100%"
                    height="20px"
                    fill="rgb(119, 119, 119,0.5)"
                  ></rect>
                  <rect
                    id="rectprog"
                    width="90%"
                    height="20px"
                    fill="rgb(240, 230, 140)"
                  ></rect>
                </svg>
              </div>
            </div>
            <div>
              <h4>Vencidas</h4>
              <div style={{ position: "relative" }}>
                <svg style={{ width: "100%", height: "20px" }}>
                  <rect
                    id="rectlow"
                    width="100%"
                    height="20px"
                    fill="rgb(119, 119, 119,0.5)"
                  ></rect>
                  <rect
                    id="rectprog"
                    width="10%"
                    height="20px"
                    fill="rgb(240, 140, 140)"
                  ></rect>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
