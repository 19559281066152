import React, { useState, useEffect, useContext } from "react";
import "semantic-ui-css/semantic.min.css";
import { useHistory } from "react-router-dom";
import "date-fns";
import "./App.css";
import userData from "./employee.json";
import { Button, Header, Segment } from "semantic-ui-react";
import { Contexto } from "./Contexto";

function Perfil() {
  const {
    ["user"]: [user, setUser],
  } = useContext(Contexto);
  const {
    ["pagina"]: [pagina, setPagina],
  } = useContext(Contexto);
  const userInfo = userData.filter((item) => {
    return item.Nombre === user;
  });
  const history = useHistory();
  return (
    <div>
      <Segment>
        <Header as="h4">
          {user}
          <Button
            floated="right"
            negative
            onClick={() => {
              setUser("");
              sessionStorage.removeItem("user");
              localStorage.removeItem("user");
              setPagina("/perfil");
              history.push("/Login");
            }}
          >
            Cerrar Sesión
          </Button>
        </Header>
        <Segment>{userInfo[0].correo}</Segment>
      </Segment>
    </div>
  );
}

export default Perfil;
