import React, { useState, useContext, useEffect } from "react";
import "semantic-ui-css/semantic.min.css";
import "date-fns";
import "./App.css";
import { GoogleSpreadsheet } from "google-spreadsheet";
import "react-datepicker/dist/react-datepicker.css";
import userData from "./employee.json";
import { Contexto } from "./Contexto";
import dummy from "./Dummy.png";

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25568);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}

function FechaStringLong(fecha) {
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  var hora = new Intl.DateTimeFormat("en-GB", options).format(fecha);
  var fechaStr = hora;
  return fechaStr;
}

function FechaStringShort(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${ye}-${mo < 10 ? "0" + mo : mo}-${da}`;
  return fechaStr;
}

function Desviaciones({ user }) {
  //Fechas
  let today = new Date();
  let startDate = new Date(today);
  startDate.setDate(startDate.getDate() - 30);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var hora = today.getHours();
  var minutos = today.getMinutes();
  dd < 10 ? (dd = "0" + dd) : (dd = today.getDate());
  mm < 10 ? (mm = "0" + mm) : (mm = today.getMonth() + 1);
  if (hora < 10) hora = "0" + hora;
  if (minutos < 10) minutos = "0" + minutos;
  let date = yyyy + "-" + mm + "-" + dd;
  //Fechas
  const [data, setData] = useState();
  const [myData, setMyData] = useState();
  const [dataFound, setDataFound] = useState();
  const [inicio, setInicio] = useState(startDate.toISOString().slice(0, 10));
  const [final, setFinal] = useState(date);
  const [activeIndex, setActiveIndex] = useState(0);
  const [estado, setEstado] = useState();
  const [Status, setStatus] = useState("Sin Acción");
  const [activeItem, setActiveItem] = useState("");
  const dummyImg = "https://react.semantic-ui.com/images/wireframe/image.png";
  const [papName, setPapName] = useState();
  const [papDesc, setPapDesc] = useState();
  const [papAreaResp, setPapAreaResp] = useState();
  const {
    ["desviaciones"]: [desviaciones, setDesviaciones],
  } = useContext(Contexto);
  const {
    ["grupoArea"]: [grupoArea, setGrupoArea],
  } = useContext(Contexto);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const sacardata = async () => {
    setEstado("Cargando");
    var elem = document.getElementById("cargando");
    elem.style.width = "100%";
    elem.style.height = "100%";
    let data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    let sheet = doc.sheetsById[1249329599];
    let rows = await sheet.getRows();
    let cols = await sheet.columnCount;
    let encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    //AREAS
    sheet = doc.sheetsById[1705686078];
    rows = await sheet.getRows();
    cols = await sheet.columnCount;
    encabezado = await sheet.headerValues;
    let datas_areas = [];
    lastr = rows.length + 1;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas_areas.push(data);
    }
    const unique = [...new Set(datas_areas.map((item) => item.grupo))];
    unique.sort();
    unique.unshift("Todas");
    setGrupoArea(unique);
    //AREAS
    datas.forEach((x) => {
      x.grupo =
        datas_areas[datas_areas.findIndex((y) => y.name === x.Area)].grupo;
    });
    setData(datas);
    let incumplimientos = datas.filter((x) => x.Incumple === "Y");
    let miData = incumplimientos;
    let misReqs = incumplimientos.filter((x) => x.AreaResponsable !== null);
    let misTasks;
    if (user) {
      var userArea = userData.filter((x) => x.Nombre === user)[0].Area;
      let grupoAreaKey = datas_areas.filter((y) =>
        y["keys"]
          .toLowerCase()
          .split(", ")
          .some((w) => userArea.toLowerCase().includes(w))
      );
      if (!userArea.toLowerCase().includes("gerencia")) {
        miData = miData.filter((x) => x.grupo === grupoAreaKey[0].grupo);
      }
      if (!userArea.toLowerCase().includes("gerencia")) {
        misReqs = incumplimientos.filter(
          (x) => x.AreaResponsable === grupoAreaKey[0].grupo
        );
      }
      misTasks = incumplimientos.filter((x) => x.Responsable === user);
    }
    setDesviaciones(miData);

    setEstado("Cargado!");
    document.getElementById("pap_block").classList.add("invisible");
    var elem = document.getElementById("cargando");
    elem.style.width = "0%";
    elem.style.height = "0%";
    setEstado();
  };

  useEffect(() => {
    if (!desviaciones) {
      sacardata();
    } else {
    }
  }, []);

  async function registrar() {
    setEstado("Guardando");
    var elem = document.getElementById("cargando");
    elem.style.width = "100%";
    elem.style.height = "100%";
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsById[1249329599];
    const rows = await sheet.getRows();
    const cols = await sheet.columnCount;
    let lastr = rows.length;
    while (lastr--) {
      if (
        rows[lastr].Id == activeItem.split("_")[0] &&
        rows[lastr].SubCategoria == activeItem.split("_")[1]
      ) {
        rows[lastr]["PAP"] = papName;
        rows[lastr].PAPDesc = papDesc;
        rows[lastr].AreaResponsable = papAreaResp;
        rows[lastr].Estatus = "Asignado";
        rows[lastr].SolicitadoPor = user;
        rows[lastr].PAPFechaCreado = FechaStringLong(new Date());
        await rows[lastr].save();
        break;
      }
    }
    setEstado("Guardado!");
    var elem = document.getElementById("cargando");
    elem.style.width = "0%";
    elem.style.height = "0%";
    setEstado();
    setActiveItem();
    sacardata();
    setPapName();
    setPapDesc();
    setPapAreaResp();
  }

  function closeNav() {
    var elem = document.getElementById("mySidenav");
    elem.style.width = "0";
    elem.style.padding = "0";
  }

  function abrir() {
    var elem = document.getElementById("mySidenav");
    elem.style.width = "100%";
    elem.style.padding = "5%";
  }

  return (
    <div className="desv">
      <div style={{ height: "100%", width: "100%" }}>
        <div
          className="filtros"
          style={{
            float: "left",
            width: "100%",
            padding: "1%",
            backgroundColor: "brown",
          }}
        >
          <div className="devTitle">
            <i class="material-icons regular" onClick={abrir}>
              menu
            </i>
            <h2 className="regular" style={{ textAlign: "center" }}>
              Desviaciones
            </h2>
          </div>
          <div id="cargando" className="cargando">
            {estado}
          </div>
          <div id="mySidenav" className="sidenav">
            <div className="sticky">
              <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>
                &times;
              </a>
              <div style={{ width: "100%" }}>
                {["Sin Acción", "En Proceso", "Cerrado"].map((x) => {
                  return (
                    <h3
                      key={"Status-" + x}
                      className="status"
                      style={{
                        opacity: Status === x ? 1 : 0.5,
                        position: "relative",
                      }}
                      onClick={() => {
                        setStatus(x);
                      }}
                    >
                      {x}
                    </h3>
                  );
                })}
              </div>
            </div>
            {desviaciones &&
            desviaciones.filter((x) =>
              Status === "Sin Acción"
                ? x.Estatus === null
                : Status === "Cerrado"
                ? x.Estatus === "Cerrado"
                : x.Estatus !== "Cerrado" && x.Estatus !== null
            ).length > 0 ? (
              desviaciones
                .filter((x) =>
                  Status === "Sin Acción"
                    ? x.Estatus === null
                    : Status === "Cerrado"
                    ? x.Estatus === "Cerrado"
                    : x.Estatus !== "Cerrado" && x.Estatus !== null
                )
                .map((x) => {
                  return (
                    <div
                      className="desvDetalle"
                      key={x.Id + "_" + x.SubCategoria}
                      onClick={() => {
                        const name = x.Id + "_" + x.SubCategoria;
                        var pap = desviaciones.filter(
                          (y) =>
                            x.Id === y.Id && x.SubCategoria === y.SubCategoria
                        )[0]["PAP"];
                        console.log(pap);
                        var papdesc = desviaciones.filter(
                          (y) =>
                            x.Id === y.Id && x.SubCategoria === y.SubCategoria
                        )[0].PAPDesc;
                        console.log(papdesc);
                        setActiveItem(name);
                        setPapAreaResp(
                          desviaciones.filter(
                            (y) =>
                              x.Id === y.Id && x.SubCategoria === y.SubCategoria
                          )[0].AreaResponsable
                        );
                        setPapName(pap);
                        setPapDesc(papdesc);
                        document.getElementById("papname").value = pap;
                        document.getElementById("papdesc").value = papdesc;
                        document
                          .getElementById("pap_block")
                          .classList.remove("invisible");
                        closeNav();
                      }}
                    >
                      <div>{x.Usuario}</div>
                      <div>{x.Area}</div>
                      <div>
                        {FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido))}
                      </div>
                      <div>{x.Tipo}</div>
                      <div>{x.Categoria}</div>
                      <div>{x.SubCategoria}</div>
                    </div>
                  );
                })
            ) : (
              <div className="desvDetalle">Sin Desviaciones</div>
            )}
          </div>
        </div>
        <div className="detalle rojo">
          {activeItem && (
            <div className="desvImg">
              <img
                src={
                  desviaciones.filter(
                    (x) =>
                      x.Id === activeItem.split("_")[0] &&
                      x.SubCategoria === activeItem.split("_")[1]
                  )[0].Imagen === null
                    ? dummy
                    : desviaciones.filter(
                        (x) =>
                          x.Id === activeItem.split("_")[0] &&
                          x.SubCategoria === activeItem.split("_")[1]
                      )[0].Imagen
                }
                alt="imagen"
                width="40%"
              ></img>
            </div>
          )}
          {activeItem && (
            <div className="detalleContent">
              <h4>
                {
                  desviaciones.filter(
                    (x) =>
                      x.Id === activeItem.split("_")[0] &&
                      x.SubCategoria === activeItem.split("_")[1]
                  )[0].Id
                }
              </h4>
              <h5>
                Desviación:{" "}
                {
                  desviaciones.filter(
                    (x) =>
                      x.Id === activeItem.split("_")[0] &&
                      x.SubCategoria === activeItem.split("_")[1]
                  )[0].SubCategoria
                }
              </h5>
              <h5>
                Detalle:{" "}
                {desviaciones.filter(
                  (x) =>
                    x.Id === activeItem.split("_")[0] &&
                    x.SubCategoria === activeItem.split("_")[1]
                )[0].Detalle === null
                  ? "Sin Detalle"
                  : desviaciones.filter(
                      (x) =>
                        x.Id === activeItem.split("_")[0] &&
                        x.SubCategoria === activeItem.split("_")[1]
                    )[0].Detalle}
              </h5>
              {desviaciones.filter(
                (x) =>
                  x.Id === activeItem.split("_")[0] &&
                  x.SubCategoria === activeItem.split("_")[1]
              )[0].PersonasObservadas !== null && (
                <h5>
                  Personas Observadas:{" "}
                  {
                    desviaciones.filter(
                      (x) =>
                        x.Id === activeItem.split("_")[0] &&
                        x.SubCategoria === activeItem.split("_")[1]
                    )[0].PersonasObservadas
                  }
                </h5>
              )}
            </div>
          )}
        </div>
        <div className="detalle corral">
          {
            <div id="pap_block" className="pap invisible">
              <form>
                <div className="PACname">
                  <label
                    onClick={() => {
                      console.log(
                        desviaciones.filter(
                          (y) =>
                            activeItem.split("_")[0] === y.Id &&
                            activeItem.split("_")[1] === y.SubCategoria
                        )[0],
                        papAreaResp,
                        papDesc,
                        papName
                      );
                    }}
                  >
                    Plan de Acción Preventiva *
                  </label>
                </div>
                <input
                  style={{
                    border: !papName ? "2px solid crimson" : "1px solid green",
                  }}
                  type="text"
                  id="papname"
                  name="papname"
                  placeholder="Nombre del Plan.."
                  onChange={(e) => {
                    setPapName(e.target.value);
                  }}
                />

                <label>Descripción *</label>
                <textarea
                  style={{
                    border: !papDesc ? "2px solid crimson" : "1px solid green",
                  }}
                  id="papdesc"
                  name="papdesc"
                  placeholder="Detalle del Plan.."
                  onChange={(e) => {
                    setPapDesc(e.target.value);
                  }}
                />

                <label>Area Responsable *</label>
                <select
                  style={{
                    border: !papAreaResp
                      ? "2px solid crimson"
                      : "1px solid green",
                  }}
                  id="arearesp"
                  name="arearesp"
                  onChange={(e) => {
                    setPapAreaResp(e.target.value);
                  }}
                >
                  {activeItem &&
                    desviaciones.filter(
                      (y) =>
                        activeItem.split("_")[0] === y.Id &&
                        activeItem.split("_")[1] === y.SubCategoria
                    )[0].AreaResponsable === null && (
                      <option disabled hidden value="Seleccionar Area" selected>
                        Seleccionar Area
                      </option>
                    )}

                  {grupoArea &&
                    grupoArea
                      .filter((x) => x != "Todas")
                      .map((x) => {
                        return x === papAreaResp ? (
                          <option key={"area" + x} value={x} selected>
                            {x}
                          </option>
                        ) : (
                          <option key={"area" + x} value={x}>
                            {x}
                          </option>
                        );
                      })}
                </select>
              </form>
              <button
                disabled={!papAreaResp || !papName || !papDesc}
                className={
                  !papAreaResp || !papName || !papDesc
                    ? "submit deshabilitado"
                    : "submit"
                }
                onClick={registrar}
              >
                Asignar
              </button>
            </div>
          }
          {activeItem && <div className="pap"></div>}
        </div>
      </div>
    </div>
  );
}

export default Desviaciones;
