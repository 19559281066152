import React, { useState, useContext, useEffect, useRef } from "react";
import "./reportes_home.css";
import { useHistory } from "react-router-dom";
import dummy from "./Dummy.png";
import { Contexto } from "./Contexto";
import { GoogleSpreadsheet } from "google-spreadsheet";

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25568);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  var date_data = new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );

  return date_data;
}

function FechaStringShort(fecha) {
  var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(fecha);
  var mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(fecha);
  var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(fecha);
  var fechaStr = `${ye}-${mo < 10 ? "0" + mo : mo}-${da}`;
  return fechaStr;
}

export default function ReportHome({ user }) {
  const history = useHistory();
  const [data, setData] = useState();
  const [areas, setAreas] = useState();
  const {
    ["grupoArea"]: [grupoArea, setGrupoArea],
  } = useContext(Contexto);
  const {
    ["tipoReporte"]: [tipoReporte, setTipoReporte],
  } = useContext(Contexto);
  function HandleNuevo() {
    if (tipoReporte === "Reporte de Recorrido") {
      history.push("/recorrido");
    } else {
      history.push("/reporte_turno");
    }
  }
  const [slideIdx, setSlideIdx] = useState(0);
  const [filtrado, setfiltrado] = useState();

  function changeSlide(e) {
    let x;
    e.target.className === "next" ? (x = 1) : (x = -1);
    let newIdx = slideIdx + x;
    var elem = document.getElementsByClassName("mySlides");
    if (newIdx >= elem.length) {
      newIdx = 0;
    } else if (newIdx < 0) {
      newIdx = elem.length - 1;
    }
    elem[slideIdx].classList.remove("default");
    elem[newIdx].classList.add("default");
    setSlideIdx(newIdx);
  }

  const [activeMenu, setActiveMenu] = useState(0);

  const sacardata = async () => {
    var elem = document.getElementById("cargando");
    elem.style.width = "100%";
    elem.style.height = "100%";
    let data = [];
    let datas = [];
    const creds = require("./sasso-app.json"); // the file saved above
    const doc = new GoogleSpreadsheet(
      "1N_nSzixyWg6OI7I7hTOQ-rfCpeln-pYV4FMtm17wIR0"
    );
    await doc.useServiceAccountAuth(creds);
    await doc.loadInfo(); // loads document properties and worksheets
    let sheet = doc.sheetsById[1249329599];
    let rows = await sheet.getRows();
    let cols = await sheet.columnCount;
    let encabezado = await sheet.headerValues;

    let lastr = rows.length + 1;
    var j, i;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas.push(data);
    }
    //AREAS
    sheet = doc.sheetsById[1705686078];
    rows = await sheet.getRows();
    cols = await sheet.columnCount;
    encabezado = await sheet.headerValues;
    let datas_areas = [];
    lastr = rows.length + 1;
    //await sheet.loadCells("A1:V" + lastr.toString());
    await sheet.loadCells({
      // GridRange object
      startRowIndex: 0,
      endRowIndex: lastr,
      startColumnIndex: 0,
      endColumnIndex: cols,
    });
    for (j = 1; j < lastr; j++) {
      data = [];
      for (i = 0; i <= cols - 1; i++) {
        window["Celda" + j + i] = sheet.getCell(j, i).value;
        data[`${encabezado[i]}`] = window["Celda" + j + i];
      }
      datas_areas.push(data);
    }
    setAreas(datas_areas);
    const unique = [...new Set(datas_areas.map((item) => item.grupo))];
    unique.sort();
    unique.unshift("Todas");
    setGrupoArea(unique);
    //AREAS
    datas.forEach((x) => {
      x.grupo =
        datas_areas[datas_areas.findIndex((y) => y.name === x.Area)].grupo;
      if (x.Incumple === "Y") {
        x.cumple = 0;
      } else {
        x.cumple = 1;
      }
      x.total = 1;
    });

    setData(datas);

    let d = new Date();
    let dm7 = new Date();
    dm7 = dm7.setDate(dm7.getDate() - 7);
    d = FechaStringShort(d);
    dm7 = FechaStringShort(dm7);
    console.log(d, dm7);
    let miReporte = datas.filter(
      (x) =>
        x.Reporte === tipoReporte &&
        FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido)) <= d &&
        FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido)) >= dm7
    );
    miReporte.sort((a, b) => b.FechaRecorrido - a.FechaRecorrido);
    let listID = [...new Set(miReporte.map((x) => x.Id))];
    setfiltrado(listID);
    var elem = document.getElementById("cargando");
    elem.style.width = "0%";
    elem.style.height = "0%";
  };

  const menu = ["Últimos 7 días", "Este Mes", "Mis Reportes", "Otros"];
  function handleMenu(e) {
    var elem = document.getElementsByClassName("item menu-boton");
    let idx = parseInt(e.target.name.split("-")[0]);
    let nombre = e.target.name.split("-")[1];
    elem[activeMenu].classList.remove("menu-activo");
    elem[idx].classList.add("menu-activo");
    setActiveMenu(idx);
    if (idx === 0) {
      let d = new Date();
      let dm7 = new Date();
      dm7 = dm7.setDate(dm7.getDate() - 7);
      d = FechaStringShort(d);
      dm7 = FechaStringShort(dm7);
      console.log(d, dm7);
      let miReporte = data.filter(
        (x) =>
          x.Reporte === tipoReporte &&
          FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido)) <= d &&
          FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido)) >= dm7
      );
      miReporte.sort((a, b) => b.FechaRecorrido - a.FechaRecorrido);
      let listID = [...new Set(miReporte.map((x) => x.Id))];
      setfiltrado(listID);
    } else if (idx === 1) {
      let miReporte = data.filter(
        (x) =>
          x.Reporte === tipoReporte &&
          FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido)).substring(
            5,
            7
          ) === FechaStringShort(new Date()).substring(5, 7)
      );
      miReporte.sort((a, b) => b.FechaRecorrido - a.FechaRecorrido);
      let listID = [...new Set(miReporte.map((x) => x.Id))];
      setfiltrado(listID);
    } else if (idx === 2) {
      let miReporte = data.filter(
        (x) => x.Reporte === tipoReporte && x.Usuario === user
      );
      miReporte.sort((a, b) => b.FechaRecorrido - a.FechaRecorrido);
      let listID = [...new Set(miReporte.map((x) => x.Id))];
      setfiltrado(listID);
    } else {
      let miReporte = data.filter((x) => x.Reporte === tipoReporte);
      miReporte.sort((a, b) => b.FechaRecorrido - a.FechaRecorrido);
      let listID = [...new Set(miReporte.map((x) => x.Id))];
      setfiltrado(listID);
    }
  }
  useEffect(() => {
    if (!data) {
      sacardata();
    } else {
      var elem = document.getElementsByClassName("item menu-boton");
      for (var ele of elem) {
        ele.classList.remove("menu-activo");
      }
      elem[0].classList.add("menu-activo");
      setActiveMenu(0);
      let d = new Date();
      let dm7 = new Date();
      dm7 = dm7.setDate(dm7.getDate() - 7);
      d = FechaStringShort(d);
      dm7 = FechaStringShort(dm7);
      console.log(d, dm7);
      let miReporte = data.filter(
        (x) =>
          x.Reporte === tipoReporte &&
          FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido)) <= d &&
          FechaStringShort(ExcelDateToJSDate(x.FechaRecorrido)) >= dm7
      );
      miReporte.sort((a, b) => b.FechaRecorrido - a.FechaRecorrido);
      let listID = [...new Set(miReporte.map((x) => x.Id))];
      setfiltrado(listID);
    }
  }, [data, tipoReporte]);

  return (
    <div className="Contenedor">
      <div id="cargando" className="cargando">
        <div className="loaderH"></div>
        Cargando...
      </div>
      <div className="ReporteHome">
        <h1 className="item cuatro">{tipoReporte}</h1>
        <div className="item cuatro">
          {data && (
            <div class="slideshow-container">
              {[
                ...new Set(
                  data
                    .sort((a, b) => b.FechaRecorrido - a.FechaRecorrido)
                    .filter(
                      (y) => y.Reporte === tipoReporte && y.Imagen != null
                    )
                    .map((o) => o.Imagen)
                ),
              ].map((y, idx) => {
                let nombre = "mySlides fade default";
                if (idx !== 0) {
                  nombre = "mySlides fade";
                }
                if (idx < 10) {
                  return (
                    <div class={nombre}>
                      <div class="numbertext">
                        {idx + 1}/
                        {[
                          ...new Set(
                            data
                              .sort(
                                (a, b) => b.FechaRecorrido - a.FechaRecorrido
                              )
                              .filter(
                                (y) =>
                                  y.Reporte === tipoReporte && y.Imagen != null
                              )
                              .map((o) => o.Imagen)
                          ),
                        ].length >= 10
                          ? 10
                          : [
                              ...new Set(
                                data
                                  .sort(
                                    (a, b) =>
                                      b.FechaRecorrido - a.FechaRecorrido
                                  )
                                  .filter(
                                    (y) =>
                                      y.Reporte === tipoReporte &&
                                      y.Imagen != null
                                  )
                                  .map((o) => o.Imagen)
                              ),
                            ].length}
                      </div>
                      <img
                        src={y}
                        style={{ width: "65%" }}
                        alt={data.filter((x) => x.Imagen === y)[0].Detalle}
                      />
                      <div class="text-carousel">
                        {data.filter((x) => x.Imagen === y)[0].Area}{" "}
                        {FechaStringShort(
                          ExcelDateToJSDate(
                            data.filter((x) => x.Imagen === y)[0].FechaRecorrido
                          )
                        )}{" "}
                        {data.filter((x) => x.Imagen === y)[0].Detalle}
                      </div>
                    </div>
                  );
                }
              })}

              <a class="prev" onClick={changeSlide}>
                &#10094;
              </a>
              <a class="next" onClick={changeSlide}>
                &#10095;
              </a>
            </div>
          )}
        </div>
        <h3 className="item cuatro">Desviaciones Recientes</h3>
        {menu.map((x, idx) => {
          let nombre = "item menu-boton";
          if (idx === 0) {
            nombre = "item menu-boton menu-activo";
          }
          return (
            <button
              className={nombre}
              key={idx + "-" + x}
              name={idx + "-" + x}
              onClick={handleMenu}
            >
              {x}
            </button>
          );
        })}
        {filtrado &&
          filtrado.map((x) => {
            return (
              <div className="item cuatro cartita">
                {activeMenu === 2 && (
                  <button className="editarReporte">
                    {" "}
                    <i class="material-icons">mode_edit</i> Editar
                  </button>
                )}
                <h4>
                  {FechaStringShort(
                    ExcelDateToJSDate(
                      data.filter((o) => o.Id === x)[0].FechaRecorrido
                    )
                  )}
                </h4>
                <p>{data.filter((o) => o.Id === x)[0].Area}</p>
                <p>{data.filter((o) => o.Id === x)[0].Usuario}</p>
                {[
                  ...new Set(
                    data
                      .filter((y) => y.Id === x && y.Imagen != null)
                      .map((o) => o.Imagen)
                  ),
                ].length > 0 && (
                  <div className="mini-carrusel">
                    {[
                      ...new Set(
                        data
                          .filter((y) => y.Id === x && y.Imagen != null)
                          .map((o) => o.Imagen)
                      ),
                    ].map((y) => {
                      return (
                        <p className="mini-carrusel-imagen">
                          <img src={y} width="50%" alt={"source: " + y}></img>
                        </p>
                      );
                    })}
                  </div>
                )}

                {[
                  ...new Set(
                    data
                      .filter((y) => y.Id === x && y.Incumple === "Y")
                      .map((o) => o.SubCategoria)
                  ),
                ].length === 0 ? (
                  <p style={{ textAlign: "left" }}>Sin Desviaciones</p>
                ) : (
                  <>
                    <p style={{ textAlign: "left" }}>Desviaciones:</p>
                    <p
                      style={{ textAlign: "left" }}
                      onClick={() =>
                        console.log(
                          new Set(
                            data
                              .filter((y) => y.Id === x && y.Incumple === "Y")
                              .map((o) => o.Tipo + ": " + o.SubCategoria)
                          )
                        )
                      }
                    >
                      {[
                        ...new Set(
                          data
                            .filter((y) => y.Id === x && y.Incumple === "Y")
                            .map((o) => o.Tipo + ": " + o.SubCategoria)
                        ),
                      ].join(" / ")}
                    </p>
                  </>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
